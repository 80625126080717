/* eslint-disable  */
import React, {useEffect, useState} from 'react'
import {
  Autocomplete, Chip,
  TextField
} from '@mui/material'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {professions, users} from '../../../app/services/Api'
import {setKeywordInspiration, setProfession} from '../../../app/quiz/quizSlice'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import {toast} from "react-toastify";

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

interface Profession {
  id: number
  name: string
}

export default function QuizProfessionPageForm({className, handleShowPage}: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const profession = useAppSelector(state => state.quizReducer.profession)
  const [isDisabled, setIsDisabled] = useState(true)
  const [availableProfessions, setAvailableProfessions] = useState<Profession[]>([])
  const [isInputEmpty, setIsInputEmpty] = useState(true)
  const [isExpertiseSectionVisible, setIsExpertiseSectionVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(() => {
    const storedProfession = localStorage.getItem('profession')
    return storedProfession !== null ? storedProfession : null
  })
  const [keywords, setKeywords] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [keywordInputValue, setKeywordInputValue] = useState<string>('')
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const otherOption: Profession = {
    id: 9999,
    name: 'Other'
  }

  const handleCustomValue = () => {
    setInputValue(inputValue.trim())

    if (inputValue && !availableProfessions.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
      setAvailableProfessions((items) => [...items, {'id': -1, 'name': inputValue}])
    }

    const option = availableProfessions.find((item) => {
      return item.name.toLowerCase() === inputValue.toLowerCase()
    })

    setSelectedOption(option?.name || '')
  }

  const handleInputChange = (event: any, value: any) => {
    setInputValue(value)
    dispatch(setProfession(value ?? null))
    setSelectedOption('')

    if (value !== '') {
      setIsInputFocused(false)
    }
  }

  useEffect(() => {
    const fetchProfessions = async () => {
      try {
        if (currentUser === null) {
          return
        }

        const response = await professions.getAllProfessions(currentUser.id)
        response.push(otherOption)

        setAvailableProfessions(response)
      } catch (error) {
        console.error('Error fetching Profession data:', error)
      }
    }

    fetchProfessions()
  }, [currentUser])

  useEffect(() => {
    setIsInputEmpty(keywordInputValue.trim() === '')
  }, [keywordInputValue])

  const handleButtonClick = (): void => {
    addKeyword()
  }

  const addKeyword = (): void => {
    const trimmedValue = keywordInputValue.trim()
    if (trimmedValue.length > 0) {
      const words = trimmedValue.split(',')
      const newKeywords = words.map(word => word.trim())

      setKeywords([...keywords, ...newKeywords])
      setKeywordInputValue('')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      addKeyword()
    }
  }

  const handleChange = async (event: any, value: any) => {
    setSelectedOption(value || '')
    dispatch(setProfession(value || ''))

    const option = availableProfessions.find((item) => {
      return item.name === value
    })

    setIsInputFocused(option !== null && option !== undefined && option.id === otherOption.id && value !== '')

    if (option) {
      if (option.id === otherOption.id) {
        setSelectedOption('')
        setInputValue('')
        dispatch(setProfession(''))
      } else {
        void users.updateRegisteredUser(currentUser?.id ?? 0, {
          profession: value
        }, true)
      }
    }
  }

  useEffect(() => {
    setIsDisabled((!selectedOption && !inputValue || (keywords.length === 0 && isExpertiseSectionVisible)))
  }, [selectedOption, inputValue, keywords])


  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isDisabled) {
      return
    }

    if (!isExpertiseSectionVisible) {
      setIsExpertiseSectionVisible(true)
      setIsDisabled(true)
      return
    }

    const option = availableProfessions.find((item) => {
      return item.name === profession
    })

    if (profession === null || option?.id === -1) {
      void professions.create({
        name: inputValue,
        email: currentUser?.email
      }).then(() => {
        saveAndGoToNextStep()
      })
    } else {
      saveAndGoToNextStep()
    }
  }

  const saveAndGoToNextStep = () => {
    void users.updateRegisteredUser(currentUser?.id ?? 0, {
      profession,
      professional_expertise: keywords.join(', '),
      step_name: 'Keywords'
    }, true).finally(() => {
      void users.getKeywordPrediction(true)
        .then((response) => {
          dispatch(setKeywordInspiration(response.prediction))
        })
    })

    handleShowPage('pageEight')
  }

  useEffect(() => {
    if (currentUser && currentUser.profession) {
      setSelectedOption(currentUser.profession)
      setKeywords(currentUser.professional_expertise?.split(', ') ?? [])
      setInputValue(currentUser.profession)
      dispatch(setProfession(currentUser.profession))
      setIsExpertiseSectionVisible(currentUser.professional_expertise !== null)
    }
  }, [currentUser]);

  const handleChipDelete = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  return (
    <div className={className}>
      <p
        className="inline-block text-sm md:text-lg px-5 py-2 md:p-0 mt-2 mb-3  visible_1">
        What is your profession?
      </p>

      <Autocomplete
        freeSolo={true}
        disablePortal
        forcePopupIcon={true}
        id="combo-box-profession"
        options={availableProfessions.map((profession) => profession.name)}
        value={selectedOption}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        popupIcon={<ArrowDropDownIcon/>}
        renderInput={(params) => <TextField focused={isInputFocused} {...params} label="Type or choose from the list"
                                            onBlur={handleCustomValue}/>}
        sx={{
          width: isSmallerScreen ? '90%' : '50%',
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isInputFocused ? 'firebrick' : '#1976d2',
          },
        }}
      />

      <div
        className={`transition-all transform ${isSmallerScreen ? 'w-[90%]' : 'w-1/2'} ${isExpertiseSectionVisible ? 'h-auto flex flex-col items-center' : 'h-0 invisible'}`}>
        <p
          className="md:inline-block text-sm md:text-lg text-center px-5 py-2 md:p-0 mt-9 md:mt-11 mb-1 md:mb-3 visible_1">
          Your profession is <b>general</b>! Please specify your expertise, which services you offer and relevant target audiences.
        </p>

        <div className='flex flex-col mt-4 mb-4 w-full'>
          <TextField
            label="Services, methods, approaches etc."
            variant="outlined"
            value={keywordInputValue}
            className='w-full'
            onKeyDown={(e) => {
              handleKeyDown(e)
            }}
            onChange={(e) => {
              if (e.target.value === ',') {
                return
              }

              setKeywordInputValue(e.target.value)
            }}
            sx={isSmallerScreen ? {
              "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                fontSize: "13px"
              }
            } : {}}
            InputProps={{
              style: {
                borderRadius: '8px',
              },
              endAdornment: (
                <button
                  type="button"
                  className={`rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300 ${isInputEmpty ? '' : 'bg-purple-200'}`}
                  onClick={handleButtonClick}
                >
                  Enter
                </button>
              )
            }}
          />
          <span className='text-center text-xs mt-2'>Separate each keyword/term with comma or press Enter ⏎.</span>
        </div>

        <div className="flex flex-wrap justify-center md:justify-start mt-4">
          {keywords.map((keyword, index) => (
            <div key={index} className="m-1">
              <Chip label={keyword} onDelete={handleChipDelete(keyword)}/>
            </div>
          ))}
        </div>
      </div>

      <button
        disabled={isDisabled}
        onClick={handleUpdateUser}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16 mt-8'
      >
        Next
      </button>
    </div>
  )
}
