import React from 'react'
import BusinessAboutPageInfo from '../../molecules/BusinessAboutPage/BusinessAboutPageInfo'
import BusinessAboutPageForm from '../../molecules/BusinessAboutPage/BusinessAboutPageForm'
import Steps from '../../molecules/Steps/Steps'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function BusinessAboutPage ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <BusinessAboutPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-10'/>
      <BusinessAboutPageForm handleShowPage={handleShowPage}
                             className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
