/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState, useEffect } from 'react'
import FaqItem from '../../atoms/FaqItems/FaqItem'
import { faqs } from '../../../app/services/Api'
import { type SupportData } from '../../../app/data/supportData'

interface Props {
  title?: string
  description?: string
  filterData?: string
  titleClass?: string
  hideCategoryTitle?: boolean
  designSubscription?: boolean
  isSupport?: boolean
}

export default function FaqItemContent (props: Props) {
  const { title, description, filterData, titleClass, hideCategoryTitle, designSubscription, isSupport = false } = props
  const [faqData, setFaqData] = useState<Record<string, SupportData[]>>({})
  const [selected, setSelected] = useState<number[]>([])

  const toggle = (i: number) => {
    if (selected.includes(i)) {
      setSelected(selected.filter((index) => index !== i))
    } else {
      setSelected([...selected, i])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response: SupportData[] = []
        if (filterData) {
          response = await faqs.getSupport(filterData)
        } else {
          response = await faqs.getSupport()
        }

        const groupedData: Record<string, SupportData[]> = {}
        response.forEach((item) => {
          const category = item.faq_category ? item.faq_category.name : 'Plans & Subscription'
          if (groupedData[category]) {
            groupedData[category].push(item)
          } else {
            groupedData[category] = [item]
          }
        })

        setFaqData(groupedData)
      } catch (error) {
        console.error(error)
      }
    }
    void fetchData()
  }, [])

  if (!faqData || Object.keys(faqData).length === 0) {
    return null
  }
  return (
    <div className="relative contactus_content">
      <div className="bg-white p-5 md:w-full md:mx-0 md:p-10 lg:p-12 flex flex-col max-w-screen-xl gap-3 justify-center rounded-[25px] mb-24">
        <h1 className={`text-3xl text-start font-medium ${title === 'FAQ' ? 'faq-title' : titleClass}`}>
          {title ?? 'FAQ'}
        </h1>
        <div className="flex flex-col items-center">
          <h2 className={`text-lg font-normal text-center md:text-left break-words text-gray-500 ${title === 'FAQ' ? 'faq-description' : ''}`} />
          {isSupport
            ? (
              <div className='support text-sm md:text-base text-left md:text-center !text-black w-full mt-2 mb-4 p-4 rounded-lg'>
                <div className='mb-2 font-medium'>
                  Can you spot your issue in the Frequently Asked Questions below?
                </div>
              </div>
              )
            : null
          }
          <div className="border-gray-100 w-full mt-4 border-b-2"></div>
        </div>
        <div className="flex flex-col">
          {Object.entries(faqData).map(([category, items], categoryIndex) => (
            <div key={categoryIndex} className="mb-8 md:mb-4">
              {!hideCategoryTitle && (
                <div className='mt-0 md:mt-4 w-auto p-2 md:p-4 ml-0 md:ml-24'>
                  <h1 className='font-bold text-xl md:text-2xl text-start '>{category}</h1>
                </div>
              )}
              {items.map((item, itemIndex) => (
                <div className="cursor-pointer" key={itemIndex}>
                  <div className={`bg-${designSubscription ? 'cursor-pointer !my-8' : 'gray-50 hover:bg-gray-100 md:w-2/3 p-2 mt-1 ml-2 md:ml-28'}`}>
                    <FaqItem
                      onClick={() => {
                        toggle(categoryIndex * 1000 + itemIndex)
                      }}
                      title={item.question}
                      description={item.answer}
                      isExpanded={selected.includes(categoryIndex * 1000 + itemIndex)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        {isSupport
          ? (
            <>
              <div className='text-xl md:text-3xl !font-extrabold !text-center !mt-6'>
                Didn’t Find Your Answer?
              </div>
              <div className='support text-sm md:text-base text-left md:text-center !text-black w-full mt-4 mb-4 p-4 rounded-lg'>
                <div>
                  You can reach us via <a className="text-blue-500 hover:text-blue-700" href="mailto:contact@mybranda.com">contact@mybranda.com</a>.
                </div>
                <div>
                  For technical issues only, please reach out to our Tech Support team at:
                  <a href="mailto:support@whatsmybranda.com" className="text-blue-500 hover:text-blue-700"> support@whatsmybranda.com</a>
                </div>
                <div className='mt-2'>
                  <strong>We’re doing our best to answer within 24h 🙏</strong>
                </div>
              </div>
            </>
            )
          : null
        }
      </div>
    </div>
  )
}
