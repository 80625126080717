/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import Card from '../../atoms/Card/Card'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { users } from 'app/services/Api'
import { setGoals } from '../../../app/quiz/quizSlice'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizGoalsPageForm ({ className, handleShowPage }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const country = useAppSelector(state => state.quizReducer.country)
  const dispatch = useAppDispatch()
  const [selectedGoals, setSelectedGoals] = useState<string[]>([])

  const cards = [
    'I want more customers',
    'I want to charge more for my services',
    'I want to overcome my fear of self exposure',
    'I want to generate more quality content',
    'I want to be more likeable',
    'I want to strengthen my existing online brand'
  ]

  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    setIsDisabled(selectedGoals.length === 0)
  }, [selectedGoals])

  useEffect(() => {
    setSelectedGoals(currentUser?.goals !== null && currentUser !== null ? currentUser.goals.split(', ') : [])
  }, [currentUser])

  const handleCardClick = (text: string) => {
    if (selectedGoals.includes(text)) {
      selectedGoals.splice(selectedGoals.indexOf(text), 1)
      setSelectedGoals(selectedGoals)
    } else {
      setSelectedGoals((prev) => [...prev, text])
    }
  }

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(setGoals(selectedGoals.join(', ')))

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      goals: selectedGoals.filter((goal) => goal !== '').join(', '),
      country,
      step_name: 'Gender'
    }, true)

    handleShowPage('pageFive')
  }

  return (
    <div className={className}>
      {cards.map((text, index) => (
        <Card
          key={index}
          id={index}
          handleCardClick={() => { handleCardClick(text) }}
          isActive={selectedGoals.includes(text)}
          text={text}
          className="quiz_page_two_form_card flex justify-center items-center relative"
        />
      ))}
      <button
        onClick={handleUpdateUser}
        disabled={isDisabled}
        className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-2.5"
      >
        Next
      </button>
    </div>
  )
}
