import React, { useEffect, useState } from 'react'
import { ReactComponent as QuizExpertiseImg } from '../../../img/expertise.svg'
import { setBusinessKeywords } from '../../../app/quiz/quizSlice'
import { Chip, CircularProgress, TextField } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { users } from 'app/services/Api'
import { toast } from 'react-toastify'
import LoadingButton from '@mui/lab/LoadingButton'
import AskBranda from '../../atoms/Icon/AskBranda'
import Steps from '../../molecules/Steps/Steps'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function BusinessKeywordsPage ({ className, currentPage, handleShowPage, handleModalOpen }: Props): JSX.Element {
  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])
  const [kwInspirations, setKwInspirations] = useState<string[]>([])
  const [isRequestProccessed, setIsRequestProccessed] = useState(false)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const keywordInspiration = useAppSelector(state => state.quizReducer.keywordInspiration)

  useEffect(() => {
    setIsDisabled(keywords.length === 0)
  }, [keywords])

  useEffect(() => {
    dispatch(setBusinessKeywords(keywords.join(', ')))
  }, [keywords, dispatch])

  useEffect(() => {
    if (keywordInspiration !== null) {
      setKwInspirations(keywordInspiration.split(', '))
    }
  }, [keywordInspiration])

  const handleSuggestedKeywordAdd = (label: string) => {
    if (keywords.includes(label)) {
      return
    }

    setKeywords([...keywords, label])
  }

  const addKeyword = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue.length > 0) {
      const newKeywords = trimmedValue.split(',').map(word => word.trim())
      setKeywords([...keywords, ...newKeywords])
      setInputValue('')
    }
  }

  const registerUser = async () => {
    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      business_keywords: keywords.join(', '),
      step_name: 'Step One'
    }, true).then((response) => {
      setIsRequestProccessed(true)
    }).catch(() => {
      setIsRequestProccessed(false)
      toast.error('Failed to change page. Please try again!')
    })

    handleShowPage('pageStep1')
  }

  const handleChipDelete = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    void registerUser()
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      addKeyword()
    }
  }

  return (
    <div className={className}>
      <div className='flex justify-end w-full custom-quiz-margin pt-5 pr-5'>
        <AskBranda handleModalOpen={handleModalOpen}/>
      </div>
      <Steps currentPage={currentPage} />
      <div className="flex flex-col justify-center relative items-center gap-3 visible_1 mt-12 md:mt-0">
        <QuizExpertiseImg/>
        <h1 className="text-xl md:text-2xl font-medium mt-10  visible_1">What would you like to talk about?</h1>
        <p
          className="md:inline-block text-sm md:text-base px-5 py-2 md:p-0 text-center mb-4 visible_1">
          Please choose keywords of general and personal discussion topics you’d like to post about. <br/>
          Or write your own in the field below!
        </p>

        <div className='bg-gray-100 rounded-2xl p-2.5 w-full md:w-1/2 mt-3 mb-4'>
          <div className='flex justify-center flex-wrap h-32 overflow-auto'>
            {kwInspirations.map((keyword, index) => (
              <div key={index} className="m-1">
                <Chip sx={{
                  opacity: keywords.includes(keyword) ? 0.5 : 1,
                  cursor: keywords.includes(keyword) ? 'default' : 'pointer',
                  color: '#000',
                  backgroundColor: '#ffffff',
                  borderColor: '#ccc',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  fontSize: '11px'
                }}
                      id={`kw-${keyword}`} label={keyword}
                      onClick={() => {
                        handleSuggestedKeywordAdd(keyword)
                      }}/>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap justify-center my-4 w-full md:w-1/2">
          {keywords.map((keyword, index) => (
            <div key={index} className="m-1">
              <Chip label={keyword} onDelete={handleChipDelete(keyword)}/>
            </div>
          ))}
        </div>

        <div className="flex flex-col visible_1 mt-4 mb-0 md:mb-10">
          <div className="flex flex-col">
            <TextField
              label='Write your own topics (optional)'
              variant="outlined"
              value={inputValue}
              className='w-96'
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}

              onChange={(e) => {
                if (e.target.value === ',') {
                  return
                }

                setInputValue(e.target.value)
              }}
              InputProps={{
                style: {
                  borderRadius: '8px'
                },
                endAdornment: (
                  <button
                    type="button"
                    className={'rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300'}
                    onClick={addKeyword}
                  >
                    Enter
                  </button>
                )
              }}
            />
            <span className='text-center text-xs mt-2'>Separate each keyword/term with comma or press Enter ⏎.</span>
          </div>
        </div>

        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator={<CircularProgress color="inherit" size={28}/>}
          disabled={isDisabled}
          color='inherit'
          className='quiz_form_button !capitalize !text-white !font-medium !rounded-lg !text-lg px-9 py-2.5 text-center mr-2 h-14'
        >
          {!loading && !isRequestProccessed ? 'Next' : ''}
        </LoadingButton>
      </div>
    </div>
  )
}
