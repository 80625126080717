import { useAppSelector } from '../../../app/hooks'
import React, { useEffect, useState } from 'react'
import TrialMainPage from './TrialMainPage'
import PremiumMainPage from './PremiumMainPage'

export default function TopicSelector () {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const isPremiumPageVisible = useAppSelector(state => state.contentGeneratorSlice.isPremiumPageVisible)
  const [isPremium, setIsPremium] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser === null) {
      return
    }

    if (currentUser.subscription.length > 0) {
      setIsPremium(currentUser.subscription[0].is_trial === false)
    }
  }, [currentUser])

  return (
    <div className={'flex flex-col justify-center w-full md:basis-9/12 p-5 md:p-16 mb-24 bg-white rounded-3xl'}>
      {(isPremium || isPremiumPageVisible)
        ? <PremiumMainPage />
        : <TrialMainPage />}
    </div>
  )
}
