/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import Link from '../../atoms/Link/Link'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setProfileSettingPage } from 'app/quiz/quizSlice'

interface Props {
  className: string
  onClick?: () => void
}

export default function SidebarLinks ({ className }: Props) {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const isSubscriptionActive = currentUser?.subscription?.length && currentUser?.subscription[0].is_active

  const manageSubscriptionPlanProfile = () => {
    dispatch(setProfileSettingPage('profile-subscription'))
  }

  return (
    <>
      <div className={className}>
        <Link
          className='text-base font-normal p-3 w-full rounded-xl transition-transform transform hover:scale-105'
          name="Home"
          linkTo={currentUser ? '/dashboard' : '/'}
          routerLink={true}
        />

        {currentUser?.has_finished_quiz && isSubscriptionActive
          ? <Link
            className={`text-base font-normal p-3 w-full rounded-xl transition-transform transform
            ${pathname === '/content-generator' || pathname.startsWith('/content-generator/')
                ? 'bg-gray-100 text-black font-semibold '
                : 'bg-white text-black hover:scale-105'
              }`}
            name="Brand Generator"
            linkTo="/content-generator"
            routerLink={true}
          />
          : null
        }

        {
          (currentUser && currentUser.has_finished_steps && isSubscriptionActive)
            ? <Link
              className={`text-base font-normal p-3 w-full rounded-xl transition-transform transform
                ${pathname === '/profile'
                  ? 'bg-gray-100 text-black font-semibold'
                  : 'bg-white text-black hover:scale-105'
                }`}
              name="Subscription Plan"
              linkTo="/profile"
              routerLink={true}
              onClick={manageSubscriptionPlanProfile}
            />
            : null
        }

        {
          !currentUser && <Link
            className={` text-base font-normal p-3 w-full rounded-xl transition-transform transform
          ${pathname === '/quiz'
                ? 'bg-gray-100 text-black font-semibold'
                : 'bg-white text-black hover:scale-105'
              }`}
            name="Start"
            linkTo={pathname === '/quiz' ? '#' : '/quiz'}
            routerLink={true}
          />
        }
        <Link
          className={`text-base font-normal p-3 w-full rounded-xl transition-transform transform
        ${pathname === '/contact-us'
              ? 'bg-gray-100 text-black font-semibold'
              : 'bg-white text-black hover:scale-105'
            }`}
          name="Contact Us"
          linkTo="/contact-us"
          routerLink={true}
        />
        <Link
          className={`text-base font-normal p-3 w-full rounded-xl transition-transform transform
        ${pathname === '/support'
              ? 'bg-gray-100 text-black font-semibold'
              : 'bg-white text-black hover:scale-105'
            }`}
          name="Support"
          linkTo="/support"
          routerLink={true}
        />
      </div>
    </>
  )
}
