import Header from '../../atoms/Heading/Heading'
import { ReactComponent as QuizProfessionPageImg } from '../../../img/quiz-profession-page-img.svg'

interface Props {
  className: string
}

export default function QuizProfessionPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <QuizProfessionPageImg />
      <Header className='text-xl md:text-2xl font-medium mt-10 mb-2' text="Tell Us More About You!" />
    </div>
  )
}
