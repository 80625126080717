import Header from '../../atoms/Heading/Heading'
import { ReactComponent as BusinessTypePageImg } from '../../../img/quiz-business-type-page-img.svg'

interface Props {
  className: string
}

export default function BusinessTypePageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <BusinessTypePageImg/>
      <Header className='text-xl md:text-2xl font-medium mt-10 mb-2' text="What's Your Business?"/>
    </div>
  )
}
