import React, { type ReactElement } from 'react'
import Tooltip from '@mui/material/Tooltip'

interface Platform {
  key: string
  task_id: number | null
  icon: ReactElement
  isVisible: boolean
  url: string
}

interface Props {
  className?: string
  platforms: Platform[]
  handlePlatformSelection: (index: number, platform: Platform) => void
  selectedPlatformIndex: number | null
}

export default function TaskPlatformList ({ platforms, handlePlatformSelection, selectedPlatformIndex, className }: Props) {
  return (
    <div className={className}>
      {platforms.map((platform: Platform, index: number) => (
        <Tooltip key={platform.key} title={platform.key}>
          <div
            className={`${platform.isVisible ? '' : 'hidden'} ${index !== selectedPlatformIndex ? 'grayscale opacity-50 hover:opacity-75' : ''} cursor-pointer`}
            onClick={() => {
              handlePlatformSelection(index, platform)
            }}>
            {platform.icon}
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
