import React, { type ReactElement, useEffect, useState } from 'react'
import { Facebook, Instagram, LinkedIn, AutoAwesome } from '@mui/icons-material/'
import { FaTiktok } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { tasks } from '../../../app/services/Api'
import { toast } from 'react-toastify'
import {
  setIsFromBrandaSuggestion, setIsPremiumPageVisibleForTrial,
  setSelectedContentHistory,
  setSelectedTopic,
  type Topic
} from '../../../app/quiz/ContentGeneratorSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import BrandaImage from '../../../img/mobile-premium-image.png'
import PremiumTopicBox from '../../molecules/ContentGenerator/PremiumTopicBox'
import BackButton from '../../atoms/BackButton/BackButton'

interface Platform {
  key: string
  name: string
  icon: ReactElement
  borderColor: string
  isDisabled: boolean
}

export default function PremiumMainPage () {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const isPremiumPageVisibleFromTrial = useAppSelector(state => state.contentGeneratorSlice.isPremiumPageVisible)
  const [randomTopicPoolIds, setRandomTopicPoolIds] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isTrialUser, setIsTrialUser] = useState<boolean>(true)

  const platforms: Platform[] = [
    {
      key: 'Facebook',
      name: 'Facebook',
      icon: <Facebook fontSize={'large'} sx={{
        color: 'blue'
      }} />,
      borderColor: 'blue',
      isDisabled: false
    }, {
      key: 'Instagram',
      name: 'Instagram',
      icon: <Instagram fontSize={'large'} sx={{
        color: '#C13584'
      }} />,
      borderColor: '#C13584',
      isDisabled: false
    }, {
      key: 'Linkedin',
      name: 'LinkedIn',
      icon: <LinkedIn fontSize={'large'} sx={{
        color: '#0077B5'
      }} />,
      borderColor: '#0077B5',
      isDisabled: false
    }, {
      key: 'Tiktok',
      name: 'TikTok',
      icon: <FaTiktok size={'28px'} />,
      borderColor: 'black',
      isDisabled: true
    }
  ]

  const [topics, setTopics] = useState<Topic[]>([])

  useEffect(() => {
    setIsLoading(true)
    void tasks.getTopics().then((response) => {
      setTopics(response)
      setRandomTopicPoolIds(response.filter((topic: Topic) => {
        return topic.include_in_suggestion && ((topic.is_trial && isTrialUser) || (!isTrialUser))
      }).map((topic: Topic) => topic.id))
    }).catch(() => {
      toast.error('Something went wrong fetching the topics. Please try again.')
    }).finally(() => {
      setIsLoading(false)
    })

    dispatch(setSelectedTopic(null))
  }, [isTrialUser])

  useEffect(() => {
    if (currentUser === null) {
      return
    }

    if (currentUser.subscription.length > 0) {
      setIsTrialUser(currentUser.subscription[0].is_trial === true)
    }
  }, [currentUser])

  const handleRandomTopicSelection = () => {
    if (randomTopicPoolIds.length === 0) {
      return
    }

    const randomTopicId: number = randomTopicPoolIds[Math.floor(Math.random() * randomTopicPoolIds.length)]
    const randomTopic: Topic | undefined = topics.find((topic: Topic) => topic.id === randomTopicId)

    if (randomTopic !== undefined) {
      handleTopicSelection(randomTopic, true)
    }
  }

  const handleTopicSelection = (topic: Topic, isSuggestion: boolean = false) => {
    dispatch(setSelectedContentHistory(null))
    dispatch(setIsFromBrandaSuggestion(isSuggestion))
    dispatch(setSelectedTopic(topic))
    navigate('/content-generator/' + topic.id)
  }

  const PremiumBadge = () => {
    return (
      <span
        className={'rounded-full z-10 absolute top-1.5 font-bold text-[10px] md:text-xs tracking-widest py-0.5 px-1.5 md:px-3 bg-[rgba(0,0,0,0.2)]'}>Premium</span>
    )
  }

  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
      <CircularProgress color="inherit" />
      </Backdrop>

      {isPremiumPageVisibleFromTrial && <BackButton
        backPage={''}
        handleBackPage={() => {
          dispatch(setIsPremiumPageVisibleForTrial(false))
        }}
        text="Back"
        className="quiz_back_button block mb-4"
      />}

      <div>
        <p className='font-bold main_header_home text-2xl md:text-4xl leading-7 text-black p-0 pb-3 text-center md:text-left'>
          Let&apos;s Post Something Amazing Together!
        </p>
        <p className={'text-lg text-center md:text-left'}>Personal Branding magic happens on your <b>personal</b> accounts</p>
      </div>

      <img src={BrandaImage} className='md:hidden mt-4' alt='Branda' />

      <p className='text-center md:text-left mt-10'>Available platforms</p>
      <div className='md:flex md:justify-between'>
        <div className='flex flex-row items-center justify-between mt-4'>
          {platforms.map((platform) => (
            <div key={platform.key} className='relative overflow-visible w-1/4'>
                <div
                  className={
                  `${platform.isDisabled ? 'opacity-40 hover:opacity-100' : ''} flex flex-col justify-center items-center md:pr-3`
                }>
                  {platform.icon}
                  {platform.isDisabled && <span className='banner left-3 md:left-[-11px] rounded inline-block mt-2'>Coming Soon</span>}
                </div>
            </div>
          ))}
        </div>
      </div>

      <div className='mt-10 md:mt-16'>
        <p className={'text-lg text-center md:text-left'}>Choose a topic or let <b>Branda</b> suggest a post for you!
        </p>
        <div className='flex flex-wrap justify-center md:justify-start mt-6 gap-3'>
          <div
            onClick={() => {
              if (isTrialUser) {
                return
              }

              handleRandomTopicSelection()
            }}
            key={'random'}
            className={`${isTrialUser ? '!opacity-60 cursor-default' : 'cursor-pointer'} suggest-button bg-center bg-cover inline-flex justify-center items-center text-center text-white p-3 border rounded-lg h-32 w-[45%] md:w-56`}>
            <span className='z-10'>Suggest <AutoAwesome fontSize={'small'}/></span>
            {isTrialUser && <PremiumBadge />}
          </div>

          {topics.map((topic, index) => (
            <PremiumTopicBox key={topic.name} index={index} topic={topic} isTrialUser={isTrialUser} />
          ))}
        </div>
      </div>
    </div>
  )
}
