const subscriptionPlans = [
  {
    id: 0,
    description: 'Basic plan for those who are just checking us out',
    branda_type: 'Trial',
    price: 'Free',
    subtitle: '',
    list_items: '5 FREE Social Media Posts generation \n Discover your unique Brand Identity \n Restricted Viral Post ideas access',
    paypro_url: '/quiz',
    cta_label: 'Start Our Free Trial',
    cta_color: '#000000',
    cta_font_color: '#ffffff',
    footnote: 'No credit card required'
  },
  {
    id: 1,
    description: 'Best for Solo-Entrepeneurs and Micro-Businesses',
    branda_type: 'Premium',
    discount_price: '$19.99',
    price: '$9.99',
    subtitle: 'Per Month',
    list_items: 'Up to <b>50</b> Social Media Posts generations \n Discover your unique Brand Identity \n Unlimited viral posts ideas access \n Write your own original posts \n Branda score <span class="coming-soon">Coming Soon</span>',
    paypro_url: '/quiz',
    cta_label: 'Start Our Free Trial',
    cta_color: '#F9D783',
    cta_font_color: '#3D4483',
    footnote: 'No credit card required'
  },
  {
    id: 2,
    description: 'Contact us for SMB’s and Enterprise custom plans',
    branda_type: 'Custom',
    price: 'Contact Us',
    subtitle: '',
    list_items: 'Custom-made branding plans \n Discover myBranda® for Business \n Become an affiliate',
    title: '<a class="underline" href="/contact-us?message=Tell me more about custom business plans!">Contact us</a> to discover myBranda’s custom-made branding plans tailored to your organization!',
    paypro_url: '/contact-us?message=Tell me more about custom business plans!',
    cta_label: 'Book A Demo',
    cta_color: '#000000',
    cta_font_color: '#ffffff'
  }
]

export default subscriptionPlans
