import Steps from '../../molecules/Steps/Steps'
import React, { useEffect, useState } from 'react'
import QuizSecondStepGenieTitle from 'components/molecules/QuizSecondStepTitle/QuizSecondStepGenieTitle'
import QuizSecondStepGenieForm from '../../molecules/QuizSecondStepForm/QuizSecondStepGenieForm'
import { quiz, users } from '../../../app/services/Api'
import { setSection } from '../../../app/quiz/quizSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../app/hooks'
import { LinearProgress, linearProgressClasses, styled } from '@mui/material'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizSecondStep ({ className, currentPage, handleShowPage }: Props) {
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const preSignedupUserId = useAppSelector(state => state.quizReducer.userPreSignupId)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const genie = useAppSelector(state => state.quizReducer.Genie)
  const Q1_1 = useAppSelector(state => state.quizReducer.Q1_1)
  const Q2_1 = useAppSelector(state => state.quizReducer.Q2_1)
  const Q2_2 = useAppSelector(state => state.quizReducer.Q2_2)
  const Q2_3 = useAppSelector(state => state.quizReducer.Q2_3)
  const Q2_4 = useAppSelector(state => state.quizReducer.Q2_4)
  const Q2_5 = useAppSelector(state => state.quizReducer.Q2_5)
  const Q2_6 = useAppSelector(state => state.quizReducer.Q2_6)
  const Q2_7 = useAppSelector(state => state.quizReducer.Q2_7)
  const Q2_8 = useAppSelector(state => state.quizReducer.Q2_8)
  const Q2_9 = useAppSelector(state => state.quizReducer.Q2_9)
  const Q2_10 = useAppSelector(state => state.quizReducer.Q2_10)
  const Q2_11 = useAppSelector(state => state.quizReducer.Q2_11)
  const Q2_12 = useAppSelector(state => state.quizReducer.Q2_12)

  const handleNextStep = () => {
    let preSignupId = 0
    if (preSignedupUserId === 0) {
      preSignupId = currentUser?.pre_signup_id ?? 0
    } else {
      preSignupId = preSignedupUserId
    }

    void quiz.getQuizSection({
      Q1_1,
      Q2_1,
      Q2_2,
      Q2_3,
      Q2_4,
      Q2_5,
      Q2_6,
      Q2_7,
      Q2_8,
      Q2_9,
      Q2_10,
      Q2_11,
      Q2_12,
      Genie: genie
    }, preSignupId).then((response) => {
      handleShowPage('pageStep3')
      dispatch(setSection(response.score.Section))
    }).catch((error) => {
      console.log(error)
    })

    void users.updateRegisteredUser(currentUser?.id ?? 0, { step_name: 'Step Three' }, true)
  }

  useEffect(() => {
    setIsDisabled(genie === '')
  }, [genie])

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800]
      })
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#8847bf',
      ...theme.applyStyles('dark', {
        backgroundColor: '#8847bf'
      })
    }
  }))

  return (
    <div className={className}>
      <div className='flex flex-col relative visible_1 justify-center items-center gap-2 my-1 mx-3 md:mx-1 lg:mx-3'>
        <Steps currentPage={currentPage}/>
        <QuizSecondStepGenieTitle className='flex flex-col justify-center items-center gap-5'/>
        <BorderLinearProgress variant={'determinate'} value={(6 / 7) * 100} className='w-full md:w-1/2 mx-auto my-2'/>
        <QuizSecondStepGenieForm className='flex w-full lg:w-auto'/>
        <button disabled={isDisabled} onClick={handleNextStep}
                className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-10 mb-7 h-16">
           Next
        </button>
      </div>
    </div>
  )
}
