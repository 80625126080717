import QuizFirstStepForm from '../../molecules/QuizFirstStepForm/QuizFirstStepForm'
import QuizFirstStepTitle from '../../molecules/QuizFirstStepTitle/QuizFirstStepTitle'
import Steps from '../../molecules/Steps/Steps'
import React from 'react'
import { LinearProgress, linearProgressClasses, styled } from '@mui/material'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizFirstStep ({ className, currentPage, handleShowPage }: Props) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800]
      })
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#8847bf',
      ...theme.applyStyles('dark', {
        backgroundColor: '#8847bf'
      })
    }
  }))

  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <QuizFirstStepTitle className='flex flex-col relative visible_1 justify-center items-center gap-5' />
      <BorderLinearProgress variant={'determinate'} value={0} className='w-full md:w-1/2 mx-auto my-2'/>
      <QuizFirstStepForm handleShowPage={handleShowPage} className='flex flex-col relative visible_1 justify-center items-center gap-3 mt-5 mx-3 md:mx-auto' />
    </div>
  )
}
