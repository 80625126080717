import BrandaResultInfo from '../../molecules/BrandaResultInfo/BrandaResultInfo'
import BrandaResultForm from '../../molecules/BrandaResultForm/BrandaResultForm'
import BrandaResultPage2Info2 from '../../molecules/BrandaResultPage2Info/BrandaResultPage2Info2'
import Confetti from '../../molecules/Confetti/Confetti'
import React, { useEffect, useState } from 'react'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaResult ({ className }: Props) {
  const [isConfettiVisible, setIsConfettiVisible] = useState(true)

  useEffect(() => {
    setIsConfettiVisible(true)
    setTimeout(() => {
      setIsConfettiVisible(false)
    }, 3000)
  }, [])

  return (
    <div className={className}>
      <Confetti visible={isConfettiVisible} />
      <BrandaResultInfo className=''/>
      <BrandaResultForm />
      <BrandaResultPage2Info2/>
    </div>
  )
}
