import React, { useEffect, useState } from 'react'
import ReactConfetti from 'react-confetti'

interface Props {
  visible: boolean
}

export default function Confetti ({ visible }: Props) {
  const [numberOfPieces, setNumberOfPieces] = useState<number>(200)
  const [isConfettiVisible, setIsConfettiVisible] = useState<boolean>(visible)

  useEffect(() => {
    if (!visible) {
      setIsConfettiVisible(false)
      return
    }

    setNumberOfPieces(200)
    setIsConfettiVisible(true)

    setTimeout(() => {
      const interval = setInterval(() => {
        setNumberOfPieces((prev) => {
          if (prev <= 0) {
            clearInterval(interval)
            setIsConfettiVisible(false)
            return 0
          }
          return prev - 100 // Decrease pieces smoothly
        })
      }, 100)
    }, 3000)
  }, [visible])

  return (
    <>
      {isConfettiVisible &&
          <div className={'z-50'}>
              <ReactConfetti className={'z-50'} gravity={0.4} recycle={false} numberOfPieces={numberOfPieces} width={window.innerWidth} height={window.innerHeight}/>
          </div>
      }
    </>
  )
}
