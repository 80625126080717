import React from 'react'
import BusinessTypePageInfo from '../../molecules/BusinessTypePage/BusinessTypePageInfo'
import BusinessTypePageForm from '../../molecules/BusinessTypePage/BusinessTypePageForm'
import Steps from '../../molecules/Steps/Steps'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function BusinessTypePage ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <BusinessTypePageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-10'/>
      <BusinessTypePageForm handleShowPage={handleShowPage} className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
