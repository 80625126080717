import React, { useEffect, useState } from 'react'
import { CheckCircleRounded } from '@mui/icons-material'
import Confetti from '../Confetti/Confetti'

interface Props {
  currentPage: string
}

export default function Steps ({ currentPage }: Props) {
  const [stepNumber, setStepNumber] = useState<number>(1)
  const [isConfettiVisible, setIsConfettiVisible] = useState(true)

  const allSteps = [
    'Sign Up',
    'Set-up',
    'Quiz'
  ]

  const stepPages = [
    ['pageOne', 'pageTwo', 'pageThree'],
    ['pageFour', 'pageFive', 'businessType', 'businessAbout', 'businessMoreInfo', 'businessKeywords', 'pageSeven', 'pageEight'],
    ['pageStep1', 'pageStep2', 'pageGenieStep', 'pageStep3']
  ]

  useEffect(() => {
    if (stepPages[0].includes(currentPage)) {
      setStepNumber(1)
    } else if (stepPages[1].includes(currentPage)) {
      setStepNumber(2)
    } else if (stepPages[2].includes(currentPage)) {
      setStepNumber(3)
    }
  }, [currentPage])

  useEffect(() => {
    setIsConfettiVisible(true)
    setTimeout(() => {
      setIsConfettiVisible(false)
    }, 3000)
  }, [stepNumber])

  return (
    <>
      <Confetti visible={isConfettiVisible}/>
      <div
        className='quiz_steps border-4 mt-6 md:mt-10 border-white text-xs md:text-base md:-translate-x-4 flex flex-row justify-between items-center mb-6 md:mb-14 md:ml-auto md:mr-auto w-full md:w-1/3'>

        {allSteps.map((stepName, index) => (
          <div
            key={'step-' + index}
            className={`quiz_steps_step flex flex-row justify-center items-center ${stepNumber === (index + 1) ? 'active' : ''} ${stepNumber > (index + 1) ? 'done' : ''}`}>
            <h6>
              {stepName}
            </h6>
            {
              stepNumber > (index + 1)
                ? <CheckCircleRounded className='text-[#552BB1] ml-1' fontSize='small'/>
                : <span
                  className='rounded-full p-0 text-[12px] md:!text-[13px] border border-black inline-flex justify-center items-center h-[17px] w-[17px] ml-1'>{index + 1}</span>
            }
          </div>
        ))
        }
      </div>
    </>
  )
}
