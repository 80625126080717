import { configureStore } from '@reduxjs/toolkit'
import quizReducer from './quiz/quizSlice'
import userSocialMediaReducer from './quiz/userSocialMedia'
import adminFaq from './quiz/adminSlice'
import contentGeneratorSlice from './quiz/ContentGeneratorSlice'
import user from './quiz/userProfile'

export const store = configureStore({
  reducer: {
    quizReducer,
    userSocialMediaReducer,
    adminFaq,
    contentGeneratorSlice,
    user
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
