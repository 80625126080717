import React, { useEffect, useState } from 'react'
import VideoPlayer from '../../molecules/Player/VideoPlayer'

interface Props {
  title: string
  videoUrl: string
  transcript: string
  allowSkip?: boolean
  lengthInSeconds?: number | null
  handleCloseModal: () => void
}

export default function VideoWithTranscript ({ title, videoUrl, transcript, handleCloseModal, lengthInSeconds = null, allowSkip = true }: Props) {
  const [showTranscript, setShowTranscript] = useState<boolean>(false)
  const [isAllowSkip, setIsAllowSkip] = useState(allowSkip)

  useEffect(() => {
    if (lengthInSeconds === null || lengthInSeconds === 0) {
      return
    }

    setTimeout(() => {
      setIsAllowSkip(true)
    }, lengthInSeconds * 1000)
  }, [])

  const toggleTranscript = () => {
    setShowTranscript(!showTranscript)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
      <div className={`bg-white p-5 rounded-lg max-w-2xl w-full ${showTranscript ? 'h-[77%]' : ''} md:h-[unset]`}>
        <h1 className='text-xl font-bold mb-3'>{title}</h1>
        <div className='flex justify-end'>
          <button className="generate absolute text-black text-xl" onClick={handleCloseModal} style={{ marginTop: '-45px' }}>
            &times;
          </button>
        </div>
        {!showTranscript
          ? <VideoPlayer filename={videoUrl}/>
          : (
            <div className="transcript-content bg-[#ffffffb8] rounded p-2"
                 style={{ height: '70%', overflowY: 'scroll' }}>
              <div dangerouslySetInnerHTML={{ __html: transcript }}/>
            </div>
            )}
        <div className="flex justify-between mt-4">
          <button
            className="copy-button px-4 py-2 bg-black rounded text-white w-28"
            onClick={toggleTranscript}
          >
            {showTranscript ? 'Video' : 'Transcript'}
          </button>
          {isAllowSkip && <button className='px-4 py-2 bg-gray-200 rounded w-28' onClick={handleCloseModal}>
            Skip
          </button>}
        </div>
      </div>
    </div>
  )
}
