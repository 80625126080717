import Header from '../../atoms/Heading/Heading'
import { ReactComponent as Image } from '../../../img/quiz-business-about-page-img.svg'

interface Props {
  className: string
}

export default function BusinessAboutPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <Image/>
      <Header className='text-xl md:text-2xl font-medium mt-10 mb-2' text="What Do You Own?"/>
    </div>
  )
}
