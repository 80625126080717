import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export enum RegenerationType {
  REGENERATE = 'Regenerate',
  REWRITE = 'Rewrite'
}

export interface ContentGeneratorState {
  selectedContentHistory: ContentHistory | null
  contentHistories: ContentHistory[]
  topic: Topic | null
  isFromBrandaSuggestion: boolean
  isHistoryItemClicked: boolean
  isPremiumPageVisible: boolean
  isGuideRunning: boolean
}

export interface Topic {
  id: number
  name: string
  short_name?: string
  description?: string
  is_trial: boolean
  include_in_suggestion: boolean
}

export interface Task {
  id: number
  task_title: string
  task_body: string
  personalization: string
  isActive: boolean
  platform: string
  subject_for_ai: boolean
  is_rewrite: boolean
  is_free_writing: boolean
}

export interface ContentHistory {
  id: number
  task: Task
  title: string
  personalization: string | null
  language: string
  response: string | null
  created_at: number
}

const initialState: ContentGeneratorState = {
  selectedContentHistory: null,
  contentHistories: [],
  isFromBrandaSuggestion: false,
  topic: null,
  isHistoryItemClicked: false,
  isPremiumPageVisible: false,
  isGuideRunning: false
}

export const contentGeneratorSlice = createSlice({
  name: 'contentGeneratorSlice',
  initialState,
  reducers: {
    setSelectedContentHistory: (state, action: PayloadAction<ContentHistory | null>) => {
      state.selectedContentHistory = action.payload
    },
    setContentHistory: (state, action: PayloadAction<ContentHistory[]>) => {
      state.contentHistories = action.payload
    },
    appendContentHistory: (state, action: PayloadAction<ContentHistory>) => {
      state.contentHistories.push(action.payload)
    },
    setIsFromBrandaSuggestion: (state, action: PayloadAction<boolean>) => {
      state.isFromBrandaSuggestion = action.payload
    },
    setSelectedTopic: (state, action: PayloadAction<Topic | null>) => {
      state.topic = action.payload
    },
    setHistoryItemClicked: (state, action: PayloadAction<boolean>) => {
      state.isHistoryItemClicked = action.payload
    },
    setIsPremiumPageVisibleForTrial: (state, action: PayloadAction<boolean>) => {
      state.isPremiumPageVisible = action.payload
    },
    setIsGuideRunning: (state, action: PayloadAction<boolean>) => {
      state.isGuideRunning = action.payload
    }
  }
})

export const {
  setSelectedContentHistory,
  setContentHistory,
  setIsFromBrandaSuggestion,
  setSelectedTopic,
  appendContentHistory,
  setHistoryItemClicked,
  setIsPremiumPageVisibleForTrial,
  setIsGuideRunning
} = contentGeneratorSlice.actions

export default contentGeneratorSlice.reducer
