import { HelpRounded } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { ClickAwayListener } from '@mui/material'

interface Props {
  className: string
  text: string
  number?: number | null
  isActive: boolean
  id: number
  handleCardClick: (id: number) => void
  tooltip?: string
}

export default function Card ({ className, text, number, isActive, id, handleCardClick, tooltip }: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div onClick={() => {
      handleCardClick(id)
    }} className={`${isActive ? className + ' active' : className}`}>
      {isActive && number !== 0 && <div className="number">{number}</div>}
      <p>{text}</p> {tooltip !== undefined &&
        <ClickAwayListener onClickAway={() => {
          setIsOpen(false)
        }}>
          <Tooltip
              disableFocusListener={true}
              disableTouchListener={true}
              disableHoverListener={true}
              onClose={() => { setIsOpen(false) }}
              onClick={() => { setIsOpen(true) }}
              open={isOpen}
              title={tooltip}>
              <HelpRounded className='scale-75 ml-2 opacity-50' />
          </Tooltip>
        </ClickAwayListener>
    }
    </div>
  )
}
