import { useEffect, useState } from 'react'

interface Props {
  timestamp: number
  className?: string
}

export default function DateDisplay ({ timestamp, className }: Props) {
  const [formattedDate, setFormattedDate] = useState<string>('')

  useEffect(() => {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    setFormattedDate(`${hours}:${minutes}\n\r${day}/${month}/${year}`)
  }, [])

  return (
    <span className={className}>{formattedDate}</span>
  )
}
