import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import React, { useEffect, useState } from 'react'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
import { Chip, TextField } from '@mui/material'
import { setKeywordInspiration, setUserBrandOffer } from '../../../app/quiz/quizSlice'
import { toast } from 'react-toastify'

interface Props {
  className: string
}

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BusinessMoreInfoPageForm ({ className, handleShowPage }: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isDisabled, setIsDisabled] = useState(true)
  const navigate = useNavigate()
  const [brandOffering, setBrandOffering] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])

  const handleKeywordAdd = () => {
    const newKeywords = brandOffering.trim().split(',').map(word => word.trim())
    setKeywords([...keywords, ...newKeywords.filter((kw) => kw !== '')])
    setBrandOffering('')
  }

  const handleKeywordRemove = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  const handleOfferingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === ',') {
      return
    }

    setBrandOffering(event.target.value)
  }

  const handleNextButton = (e: React.FormEvent) => {
    e.preventDefault()

    if (currentUser === null) {
      navigate('/sign-in')
      return
    }

    void users.updateRegisteredUser(currentUser.id, {
      business_offer: keywords.join(', '),
      step_name: 'BusinessKeywords'
    }, true).then(() => {
      dispatch(setUserBrandOffer(brandOffering))

      void users.getKeywordPrediction(true)
        .then((response) => {
          dispatch(setKeywordInspiration(response.prediction))
        })

      handleShowPage('businessKeywords')
    })
      .catch(() => {
        toast.error('URL is in the wrong format')
      })
  }

  useEffect(() => {
    setIsDisabled(keywords.length === 0)
  }, [keywords])

  useEffect(() => {
    if (currentUser !== null) {
      setKeywords((currentUser.business_offer !== null) ? currentUser.business_offer.split(', ') : [])
      dispatch(setUserBrandOffer(brandOffering))
    }
  }, [currentUser])

  return (
    <div className={className}>
      <TextField
        id="brand-offering"
        placeholder={'Plus Size women\'s active wear'}
        label="Type your offerings separated with commas or press Enter"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        value={brandOffering}
        onChange={handleOfferingChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ',') {
            handleKeywordAdd()
          }
        }}
        InputProps={{
          endAdornment: (
            <button
              type="button"
              className={`rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300 ${brandOffering === '' ? '' : 'bg-purple-200'}`}
              onClick={handleKeywordAdd}
            >
              Enter
            </button>
          )
        }}
        sx={{ width: isSmallerScreen ? '90%' : '50%' }}
      />

      <div className="flex flex-wrap justify-center md:justify-start mt-4">
        {keywords.map((keyword, index) => (
          <div key={index} className="m-1">
            <Chip label={keyword} onDelete={handleKeywordRemove(keyword)}/>
          </div>
        ))}
      </div>

      <button
        disabled={isDisabled}
        onClick={handleNextButton}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16  mt-8'
      >
        Next
      </button>
    </div>
  )
}
