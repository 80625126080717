import React, { useEffect, useState } from 'react'
import Heading from '../../atoms/Heading/Heading'

import { AutoAwesome } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { tasks } from '../../../app/services/Api'
import { toast } from 'react-toastify'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Pagination, Navigation } from 'swiper/modules'
import {
  setIsFromBrandaSuggestion, setIsPremiumPageVisibleForTrial,
  setSelectedContentHistory,
  setSelectedTopic, type Topic
} from '../../../app/quiz/ContentGeneratorSlice'
import { useDispatch } from 'react-redux'

import AOS from 'aos'
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

interface TopicBoxProp {
  topic: Topic
  index: number
  buttonLabel?: string
  handleClick: () => void
  isForMobile?: boolean
  numberLabel?: string
}

export default function TrialMainPage () {
  const dispatch = useDispatch()
  const [topics, setTopics] = useState<Topic[]>([])
  const [isMobile, setIsMobile] = useState(false)

  const boxTitles = [
    'Educate',
    'Inspire',
    'Promote',
    'Discover'
  ]

  const boxColors = [
    '#8847BF',
    '#F60085',
    '#FAAD4F',
    '#BF47BD'
  ]

  const premiumTopic: Topic = {
    id: 0,
    is_trial: true,
    name: 'Discover Premium features',
    include_in_suggestion: false
  }

  const navigate = useNavigate()

  useEffect(() => {
    void tasks.getTopics().then((response) => {
      setTopics(response.filter((topic: Topic) => topic.is_trial))
    }).catch(() => {
      toast.error('Something went wrong fetching the topics. Please try again.')
    })

    AOS.init({ duration: 1600 })

    dispatch(setSelectedTopic(null))
  }, [])

  const updateScreenWidth = () => {
    setIsMobile(window.innerWidth <= 768)
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)
    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  })

  const handleTopicSelection = (topic: Topic) => {
    dispatch(setSelectedContentHistory(null))
    dispatch(setIsFromBrandaSuggestion(false))
    dispatch(setSelectedTopic(topic))
    navigate('/content-generator/' + topic.id)
  }

  const TopicBox = (props: TopicBoxProp) => {
    const { topic, index, numberLabel, buttonLabel, isForMobile, handleClick } = props
    const buttonElement = (
      <div className='w-full'>
        <button
          onClick={handleClick}
          className={`bg-[${boxColors[index]}] text-white rounded-lg px-9 py-2.5 text-center relative mt-5 md:h-12 w-full`}>
          {buttonLabel ?? <span>Suggest <AutoAwesome fontSize={'small'}/></span>}
        </button>
      </div>
    )

    if (isForMobile !== undefined && isForMobile) {
      return (
        <div className='shadow-xl rounded-3xl !flex flex-col items-center px-5 py-10'>
              <span
                className={`mb-6 text-lg text-white inline-flex justify-center items-center rounded-full bg-[${boxColors[index]}] h-10 ${numberLabel !== undefined ? 'px-3' : 'w-10'} border border-white shadow-md`}>{numberLabel ?? (index + 1)}</span>
          <span className='text-xl text-center mb-2 font-medium'>{boxTitles[index]}</span>
          <span className='text-center text-sm text-slate-500'>{topic.name}</span>
          {buttonElement}
        </div>
      )
    }

    return (
      <div
        className={'w-full md:w-[27%] hidden md:flex flex-col items-center justify-center relative shadow-2xl px-4 py-10 rounded-3xl'}
        data-aos="fade-right" data-aos-delay={index * 200}
        key={topic.name}>
            <span
              className={`mb-6 text-lg text-white inline-flex justify-center items-center rounded-full bg-[${boxColors[index]}] h-10 ${numberLabel !== undefined ? 'px-3' : 'w-10'} border border-white shadow-md`}>{numberLabel ?? (index + 1)}</span>
        <span className='text-2xl text-center mb-2 font-medium'>{boxTitles[index]}</span>
        <span className='text-center text-sm text-slate-500'>{topic.name}</span>
        {buttonElement}
      </div>
    )
  }

  return (
    <div>
      <Heading
        className="flex main_header_home justify-center md:justify-start font-bold text-3xl md:text-4xl leading-7 text-black p-0 pb-5"
        text="Let's Start Branding You Up!"
      />
      <p className={'text-base md:text-lg'}>Here are the most effective 3 topics to <b>jumpstart</b> your brand 🔥</p>

      {isMobile && <p className='mt-12 text-center'>Swipe for more topics <FontAwesomeIcon icon={solid('arrow-right')}
                                                                                           className='ml-3'/></p>}
      {isMobile && <Swiper
          pagination={{
            type: 'progressbar'
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className='mt-1'
      >
        {topics.map((topic, index) => (
          <SwiperSlide className={'relative pb-10 px-6'} key={topic.name}>
            <TopicBox isForMobile={true} topic={topic} index={index} handleClick={() => {
              handleTopicSelection(topic)
            }} />
          </SwiperSlide>
        ))}

          <SwiperSlide className={'relative pb-10 px-6'} key={premiumTopic.name}>
              <TopicBox isForMobile={true} topic={premiumTopic} index={3} numberLabel='Bonus ⭐️' buttonLabel='Learn 💡' handleClick={() => {
                dispatch(setIsPremiumPageVisibleForTrial(true))
              }} />
          </SwiperSlide>
      </Swiper>
      }

      <div className={`${isMobile ? '' : 'hidden'} px-10`}>
        <div onClick={() => {
          navigate('/subscription')
        }} className='sidebar-upgrade-mobile text-xs rounded-3xl py-3 px-2 flex flex-col justify-center text-white'>
          <p className='text-center'>
            Unlock 20X more content with <b>Premium</b>
          </p>
        </div>
      </div>

      <div className={'flex flex-col md:flex-row relative mt-20 gap-x-6'}>
        {topics.map((topic, index) => (
          <TopicBox topic={topic} key={index} index={index} handleClick={() => {
            handleTopicSelection(topic)
          }} />
        ))}

        <TopicBox topic={premiumTopic} key={3} index={3} numberLabel='Bonus ⭐️' buttonLabel='Learn 💡' handleClick={() => {
          dispatch(setIsPremiumPageVisibleForTrial(true))
        }} />
      </div>
    </div>
  )
}
