/* eslint-disable */
import React from 'react'
import Header from 'components/atoms/Heading/Heading'
import { ReactComponent as QuizFinishPage } from '../../../img/quiz-pre-result.svg'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaPreResultInfo ({ className, handleShowPage }: Props) {
  const handleButtonClick = () => {
    handleShowPage('pageBrandaResult')
  }

  return (
    <div className={className}>
      <div>
        <div className="flex justify-center items-center">
          <QuizFinishPage/>
        </div>
        <div className='flex flex-col justify-center items-center gap-3'>
          <Header className='text-xl font-medium text-center text-white  mt-5 md:text-2xl w-72 md:w-auto'
                  text='Your Branda Is Here ⚡️'/>
        </div>
      </div>

      <p className='text-center text-white opacity-75 w-5/6 md:w-2/3'>
        Ready to discover your unique Brand Identity? It’s just a click away 👇🏻 <br/>
        Remember! Your Branda is not you. It’s an aspired Brand Image to make your story more memorable and consistent online ✨
      </p>

      <div className='flex flex-col justify-center items-center gap-3 mt-6'>
        <div className='flex flex-col md:flex-row gap-4 md:gap-6 mb-4'>
          <div className='flex flex-col'>
            <button onClick={handleButtonClick} className='bg-white p-4 rounded-lg text-sm font-semibold w-72'>
              <span className='hover:text-purple-800 hover:text-base ease-in duration-150'>Get my Branda</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
