/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  */
import Heading from '../../atoms/Heading/Heading'
import Button from '../../atoms/Button/Button'
import { Link } from 'react-router-dom'
import HomeDesktopImg from '../../../img/home-img.png'
import HomeMobileImg from '../../../img/home-mobile.png'
import Image from '../../atoms/Image/Image'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'app/hooks'
import EndSubscriptionModal from '../ProfileForm/Modals/EndSubscriptionModal'
import EndTrialModal from '../ProfileForm/Modals/EndTrialModal'

interface Props {
  className: string
}

export default function Headers ({ className }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [homeImage, setHomeImage] = useState(HomeDesktopImg)
  const [userLink, setUserLink] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false)

  const toggleTrialModalState = () => {
    const subscription = currentUser?.subscription[0]
    const today = new Date()
    const todayDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
    // setIsTrialModalOpen(!!(subscription && subscription.valid_until < todayDate && subscription.type === -1))
  }

  const toggleModal = () => {
    if (currentUser && currentUser.subscription && currentUser.subscription.length > 0) {

      if (currentUser.subscription[0].type === -1) {
        toggleTrialModalState()
      } else {
        setIsModalOpen(!currentUser.subscription[0].is_active)
      }
    }
  }


  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeTrialModal = () => {
    setIsTrialModalOpen(false)
  }

  const updateScreenWidth = () => {
    setIsScreenWidthAbove768(true)
    if (window.innerWidth < 600) {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    if (currentUser) {
      const queryParams = new URLSearchParams(location.search)
      const isRemindLaterSet = queryParams.get('remind-later')

      if (currentUser.has_finished_quiz && !isRemindLaterSet) {
        setUserLink('/content-generator')
      } else if (currentUser.remind_me_later_sent || isRemindLaterSet) {
        setUserLink('/quiz?create-subscription=1&user=' + currentUser.id)
      } else {
        setUserLink('/quiz')
      }

      if (currentUser.subscription && currentUser.subscription.length > 0) {
        setIsModalOpen(!currentUser?.subscription[0].is_active)
      }

      toggleTrialModalState()

    } else {
      setUserLink('/quiz')
    }
  }, [currentUser])

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  useEffect(() => {
    setHomeImage(isScreenWidthAbove768 ? HomeDesktopImg : HomeMobileImg)
  }, [isScreenWidthAbove768])

  return (
    <div className={`${className} !h-[86vh] lg:!h-[100vh]`}>
      <Image className="absolute h-full w-full -z-1 object-cover object-top" src={homeImage} />
      <h1 className='main_header_home text-white ml-5 md:ml-10 pb-2.5 md:pb-3.5 font-bold text-6xl md:text-8xl mt-5 md:mt-0 hidden md:block'>
        Hi Again
      </h1>
      <h1 className='main_header_home text-white ml-5 md:ml-10 pb-2.5 md:pb-3.5 font-bold text-6xl md:text-8xl mt-5 md:mt-0 md:hidden'>
        Hi <br/> Again
      </h1>

      <div className="mb-32 md:mb-0">
        <Heading
          className='text-white text-sm font-light md:font-normal md:text-2xl ml-5 pt-4 md:ml-10'
          text='It’s great having you here!'
        />
        <Link to={userLink} className='ml-5 mb-5 md:mb-0 md:ml-10'>
          <Button
            text={currentUser ? (currentUser?.has_finished_quiz ? 'Welcome Back!' : 'Resume registration') : 'Start My Free Trial!'}
            className="brand_me_up_and_quiz_button text-white font-normal rounded-lg text-sm md:text-lg p-3 md:px-6 md:py-4 mt-5 md:mt-10 text-center"
          />
        </Link>
      </div>
      <EndSubscriptionModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onCloseModal={closeModal}
      />
      <EndTrialModal
          isOpen={isTrialModalOpen}
          onCloseModal={closeTrialModal}
      />
    </div>
  )
}
