/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Tasks {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async getTask (taskId: number): Promise<any> {
    return await this.api.get<object>(`tasks/${taskId}/`)
  }

  async getAllTasks(taskType?: string): Promise<any> {
    const queryParam = taskType ? `?task_type=${taskType}` : '';
    return await this.api.get<object[]>(`tasks/${queryParam}`)
  }

  async getTopics(id?: number): Promise<any> {
    return await this.api.get<object[]>(`topics/${id ?? ''}`)
  }

  async create(data: object, credentials?: boolean) {
    return await this.api.post<object>('tasks/', data, credentials)
  }

  async update (id: number, data: object): Promise<any> {
    return await this.api.put<object>(`tasks/${id}/`, data, true)
  }

  async delete (id: number, credentials?: boolean): Promise<any> {
    return await this.api.delete<object>(`tasks/${id}/`, { id }, credentials)
  }

  async getGenerationTasksByTopicId(topicId: number, userId: number): Promise<any> {
    return await this.api.get<object[]>(`generation_tasks/?topic_id=${topicId}&user_id=${userId}`)
  }

  async generate(
    userId: number,
    taskId: number,
    personalization: string,
    language: string,
    freeWriting: boolean,
    regenerationType: string | null = null,
    historyId: number | null = null,
    regenerationNote: string | null = null
  ): Promise<any> {
    return await this.api.post<object[]>('generate_contents/', {
      task: taskId,
      user: userId,
      personalization: personalization,
      language: language,
      free_writing: freeWriting,
      regenerate_history: historyId,
      regeneration_type: regenerationType,
      regenerate_note: regenerationNote
    })
  }
}
