import React from 'react'
import ReactPlayer from 'react-player'

interface Props {
  filename: string
}

export default function VideoPlayer ({ filename }: Props) {
  return (
        <ReactPlayer url={`https://my-branda-s3-bucket.s3.amazonaws.com/videos/${filename}`} playsinline={true} controls height='60%' width="100%" />
  )
}
