import Tooltip from '@mui/material/Tooltip'
import { ContentCopy } from '@mui/icons-material'
import React from 'react'

interface Props {
  isGenerateButtonDisabled: boolean
  isTextGenerated: boolean
  handleCopy: () => void
  handleGenerate: () => void
  className?: string
  tooltipText?: string
  isRegenerate?: boolean
  isCopyButtonHidden: boolean
}

export default function ButtonGroup ({ isGenerateButtonDisabled, isTextGenerated, handleCopy, handleGenerate, className, isCopyButtonHidden = false, isRegenerate = false, tooltipText = '' }: Props) {
  const isMobile = window.innerWidth < 768

  return (
    <div className={className}>
      <Tooltip title={isGenerateButtonDisabled ? tooltipText : ''}>
        <button
          onClick={() => {
            if (!isGenerateButtonDisabled) {
              handleGenerate()
            }
          }}
          className={`${isGenerateButtonDisabled ? 'opacity-75 cursor-default' : 'hover:bg-[#6e2fa3]'} generate bg-[#8847BF] text-white rounded-lg px-9 py-3 md:py-2.5 text-center relative mt-4 md:h-16 w-full md:w-1/2`}>
          {isRegenerate ? 'Regenerate' : 'Generate'} Post ✨
        </button>
      </Tooltip>
      {!(isCopyButtonHidden && isMobile) &&
      <button
        onClick={handleCopy}
        className={`${isTextGenerated ? 'hover:bg-[#f1e0ff]' : 'cursor-default opacity-60'} w-full md:w-1/2 bg-white text-[#8847BF] copy-button border-2 border-[#8847BF] rounded-lg px-9 py-2.5 text-center relative mt-4 md:h-16`}>
        Copy and Post <ContentCopy/>
      </button>}
    </div>
  )
}
