/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import { users } from './Api'

interface Props {
  children: JSX.Element
  redirectTo?: string
}

export const ProtectedRoute = ({ children, redirectTo = '/sign-in' }: Props) => {
  const navigate = useNavigate()

  useEffect(() => {
    const getUser = async () => {
      await users.getCurrentUser(true).then(response => {
        if (response.response) {
          navigate(redirectTo)
        }
      }).catch(err => {
        console.log(err)
        navigate(redirectTo)
      })
    }
    void getUser()
  }, [])

  return children || <Outlet />
}
