import Header from '../../atoms/Heading/Heading'
import { ReactComponent as Image } from '../../../img/quiz-business-more-info-page-img.svg'
import { useAppSelector } from '../../../app/hooks'
import React from 'react'

interface Props {
  className: string
}

export default function BusinessMoreInfoPageInfo ({ className }: Props) {
  const brandName = useAppSelector(state => state.quizReducer.brandName)

  return (
    <div className={className}>
      <Image/>
      <Header className='text-center text-xl md:text-2xl font-medium mt-10 mb-1' text={`What does ${brandName} offer?`}/>
      <p
        className="md:inline-block text-sm md:text-base px-5 py-2 md:p-0 text-center mb-7 visible_1">
        Please specify your <b>exact</b> business offerings and target audience
      </p>
    </div>
  )
}
