/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import QuizEmailPage from '../../organisms/QuizEmailPage/QuizEmailPage'
import QuizGoalsPage from '../../organisms/QuizGoalsPage/QuizGoalsPage'
import QuizFirstStep from '../../organisms/QuizFirstStep/QuizFirstStep'
import QuizThirdStep from '../../organisms/QuizThirdStep/QuizThirdStep'
import QuizSecondStep from '../../organisms/QuizSecondStep/QuizSecondStep'
import BackButton from '../../atoms/BackButton/BackButton'
import QuizGenderPage from '../../organisms/QuizGenderPage/QuizGenderPage'
import QuizProfessionPage from '../../organisms/QuizProfessionPage/QuizProfessionPage'
import QuizVerificationPage from '../../organisms/QuizVerificationPage/QuizVerificationPage'
import BrandaResult from '../../organisms/BrandaResult/BrandaResult'
import { useAppSelector } from '../../../app/hooks'
import QuizPasswordPage from '../../organisms/QuizPasswordPage/QuizPasswordPage'
import QuizExpertise from 'components/organisms/QuizExpertisePage/QuizExpertise'
import { useNavigate } from 'react-router-dom'
import BusinessTypePage from '../../organisms/BusinessTypePage/BusinessTypePage'
import BusinessAboutPage from '../../organisms/BusinessAboutPage/BusinessAboutPage'
import BusinessMoreInfoPage from '../../organisms/BusinessMoreInfoPage/BusinessMoreInfoPage'
import BusinessKeywordsPage from '../../organisms/BusinessKeywordsPage/BusinessKeywordsPage'
import BrandaPreResultInfo from '../../molecules/BrandePreResult/BrandaPreResult'
import VideoWithTranscript from '../../organisms/VideoWithTranscript/VideoWithTranscript'
import QuizGenieStep from '../../organisms/QuizGenieStep/QuizGenieStep'

const videoUrl1 = 'QuizW.mp4'
const mobileVideoUrl1 = 'AppQuizmobile.mp4'
const videoUrl2 = 'solo-kw-web.mp4'
const mobileVideoUrl2 = 'solo-kw-mobile.mp4'
const videoUrl3 = 'microbusiness-kw-web.mp4'
const mobileVideoUrl3 = 'microbusiness-kw-mobile.mp4'
const transcriptContent1 = `
  <div style="font-size:12px;">
<p>
Hi! <br>
Branda here, your personal Branding coach. Thanks for signing up! <br> 
I'm so excited you’re here with us! <br>
Now, let's get to work!
</p>
    <br>
<p>
The first step to a winning brand is discovering your Branda. Your personal brand identity. <br>
For this, I will need your help! <br>
You see, your Brand Persona is based on your unique personality and goals! This is why I will need to get to know you better first... <br> 
Please answer our short research-based Branda quiz. It will take a few minutes... Make sure to answer all the questions. And don't worry! This is a one-time process! <br> 
 I will walk you through all the way! Whenever you need my guidance, simply click on the question mark icon, and I'll be there, explaining what to do. <br>
</p>
<br>
<p>
After you finish the quiz, I will send your Branda straight to your email, so you can learn more about your brand identity, share it with friends and save it for later! <br>
Ready? Let's start branding you up!
</p>
</div>
`
const transcriptContent2 = `
  <div>
    <p style="font-size:12px;">
    This part is pretty important, so I figured I’d jump in and make sure you do it perfectly. <br> <br>
  Please choose five keywords that specify your expertise and the main themes you would like to discuss in your social media posts. I’ve already prepared many relevant suggestions for you! <br>
  Simply click on the keywords that best describe the topics you’d like to write about. Then click on the + icon to add them to the list. <br>
  Make sure to choose topics that are based both on your professional expertise and your personal interests. <br>
  Let’s take me, for example. <br>
  As you already know, MY profession is a Personal Branding coach, and I’m highly experienced with the following topics: <br>
  Online Persona building, AI , and Content Writing. These are my professional keywords. However, I would also add one or more general topics that I’d like to discuss, such as Self-Empowerment and motivation.  But this is me... <br>
  Now it’s time you think about yours. <br> <br>
  Couldn’t find 5 matching keywords? No worries. You can also write your own topics in the open text field and press Enter! <br>
  Choose wisely! These keywords will stay with you for a while... <br> <br>
  Good Luck! <br>
  You’ve got this!
    </p>
  </div>
`

const transcriptContent3 = `
  <div>
    <p style="font-size:12px;">
This part is pretty important, so I figured I’d jump in and make sure you do it perfectly.<br><br>
Please choose the main discussion topics you want to discuss in your social media posts. I’ve already prepared many relevant suggestions for you!<br>
Simply click on the keywords that best describe the topics you’d like to write about.<br><br>
As a Business owner, the way to choose the best topics is based on three principles:<br>
1. Choose the topics that are based on your business’s-industry trends<br>
2. Consider your own professional expertise and topics you have knowledge of and can add more insights about.<br>
3. Pick at least one topic that matches your personal interests or values.<br><br>
Remember, our work here is to build your individual online persona; it’s not just about your business. It’s about YOU. We will promote your business indirectly by crafting meaningful content that brings value to your audience!<br>
Couldn’t find matching keywords? No worries!<br>
You can also write your own topics in the open text field!<br>
Choose wisely!<br>
These keywords will stay with you for a while...<br><br>
Good Luck!<br>
You’ve got this!
    </p>
  </div>
`
export default function Quiz (): JSX.Element {
  const videosByPage: Record<string, {
    web: Array<{ videoUrl: string, transcriptUrl: string, title: string }>
    mobile: Array<{ videoUrl: string, transcriptUrl: string, title: string }>
  }> = {
    pageFour: {
      web: [
        { videoUrl: videoUrl1, transcriptUrl: transcriptContent1, title: 'Welcome to myBranda!' }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl1, transcriptUrl: transcriptContent1, title: 'Welcome to myBranda!' }
      ]
    },
    pageSeven: {
      web: [
        { videoUrl: videoUrl2, transcriptUrl: transcriptContent2, title: 'How to choose my perfect keywords?' }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl2, transcriptUrl: transcriptContent2, title: 'How to choose my perfect keywords?' }
      ]
    },
    pageEight: {
      web: [
        { videoUrl: videoUrl2, transcriptUrl: transcriptContent2, title: 'How to choose my perfect keywords?' }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl2, transcriptUrl: transcriptContent2, title: 'How to choose my perfect keywords?' }
      ]
    },
    businessKeywords: {
      web: [
        { videoUrl: videoUrl3, transcriptUrl: transcriptContent3, title: 'How to choose my perfect keywords?' }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl3, transcriptUrl: transcriptContent3, title: 'How to choose my perfect keywords?' }
      ]
    },
    businessMoreInfo: {
      web: [
        { videoUrl: videoUrl3, transcriptUrl: transcriptContent3, title: 'How to choose my perfect keywords?' }
      ],
      mobile: [
        { videoUrl: mobileVideoUrl3, transcriptUrl: transcriptContent3, title: 'How to choose my perfect keywords?' }
      ]
    }
  }

  const storedCurrentPage = localStorage.getItem('quizCurrentPage')
  const initialPage = storedCurrentPage ?? 'pageOne'

  const [currentPage, setCurrentPage] = useState(initialPage)
  const [isModalOpen, setModalOpen] = useState<boolean>(true)
  const [backPage, setBackPage] = useState('')
  const isBusiness = useAppSelector((state) => state.quizReducer.isBusiness)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const navigate = useNavigate()

  const handleCloseModal = (): void => {
    setModalOpen(false)
  }

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }

  const currentVideoSet = videosByPage[currentPage]
  const currentVideos = currentVideoSet ? currentVideoSet.web : []
  const currentVideo = currentVideos[0]

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Goals') {
      setCurrentPage('pageFour')
    } else if (currentUser?.quiz_step?.step_name === 'Gender') {
      setCurrentPage('pageFive')
    } else if (currentUser?.quiz_step?.step_name === 'BusinessType') {
      setCurrentPage('businessType')
    } else if (currentUser?.quiz_step?.step_name === 'BusinessAbout') {
      setCurrentPage('businessAbout')
    } else if (currentUser?.quiz_step?.step_name === 'BusinessMoreInfo') {
      setCurrentPage('businessMoreInfo')
    } else if (currentUser?.quiz_step?.step_name === 'BusinessKeywords') {
      setCurrentPage('businessKeywords')
    } else if (currentUser?.quiz_step?.step_name === 'Profession') {
      setCurrentPage('pageSeven')
    } else if (currentUser?.quiz_step?.step_name === 'Keywords') {
      setCurrentPage('pageEight')
    } else if (currentUser?.quiz_step?.step_name === 'Step One') {
      setCurrentPage('pageStep1')
    } else if (currentUser?.quiz_step?.step_name === 'Step Two') {
      setCurrentPage('pageStep2')
    } else if (currentUser?.quiz_step?.step_name === 'Step Three') {
      setCurrentPage('pageStep3')
    } else if (currentUser?.quiz_step?.step_name === 'Page Result') {
      setCurrentPage('pageBrandaResult2')
    } else if (currentUser?.quiz_step?.step_name === 'Page Thank You') {
      setCurrentPage('pageBrandaThankYou')
    }
  }, [currentUser])

  useEffect(() => {
    if (currentPage === 'pageFour') {
      setBackPage('pageThree')
    } else if (currentPage === 'pageFive') {
      setBackPage('pageFour')
    } else if (currentPage === 'pageSeven') {
      setBackPage('businessType')
    } else if (currentPage === 'businessType') {
      setBackPage('pageFive')
    } else if (currentPage === 'businessAbout') {
      setBackPage('businessType')
    } else if (currentPage === 'businessMoreInfo') {
      setBackPage('businessAbout')
    } else if (currentPage === 'businessKeywords') {
      setBackPage('businessMoreInfo')
    } else if (currentPage === 'pageEight') {
      setBackPage(isBusiness ? 'businessMoreInfo' : 'pageSeven')
    }

    if (!currentUser && backPage) {
      navigate('/sign-in')
    }

    if (currentPage) {
      setModalOpen(['pageFour'].includes(currentPage))
    }

    localStorage.setItem('quizCurrentPage', currentPage)
  }, [currentPage])

  const handleShowPage = (page: string): void => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  const handleBackPage = (page: string): void => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  let button
  if (
    currentPage === 'pageOne' ||
    currentPage === 'pageTwo' ||
    currentPage === 'pageThree' ||
    currentPage === 'pageFour' ||
    currentPage === 'pageBrandaResult' ||
    currentPage === 'pageBrandaResult2' ||
    currentPage === 'pageBrandaThankYou' ||
    currentPage === 'pageStep1' ||
    currentPage === 'pageStep2' ||
    currentPage === 'pageStep3' ||
    currentPage === 'pageBrandaPreResult' ||
    currentPage === 'pageGenieStep'
  ) {
    button = null
  } else {
    button = (
      <BackButton
        backPage={backPage}
        handleBackPage={handleBackPage}
        text="Back"
        className="quiz_back_button z-10 block absolute"
      />
    )
  }

  return (
    <>
      {button != null && (
        <div className="relative">
          {button}
        </div>
      )}

      {isModalOpen && currentVideo && (
        <VideoWithTranscript
          title={currentVideo.title}
          videoUrl={currentVideo.videoUrl}
          transcript={currentVideo.transcriptUrl}
          handleCloseModal={handleCloseModal}
        />
      )}

      <QuizEmailPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageOne'
            ? 'quiz_email_page visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28'
            : 'quiz_email_page hidden'
        }
      />

      <QuizVerificationPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageTwo'
            ? 'quiz_verification_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'
            : 'quiz_verification_page hidden'
        }
      />

      <QuizPasswordPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageThree'
            ? 'quiz_password_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'
            : 'quiz_password_page hidden'
        }
      />

      <QuizGoalsPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageFour'
            ? 'quiz_goals_page visible relative flex flex-col justify-center items-center bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 p-4 pb-20  mb-28'
            : 'quiz_goals_page hidden'
        }
        handleModalOpen={handleModalOpen}
      />

      <QuizGenderPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageFive'
            ? 'quiz_gender_page visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_gender_page hidden'
        }
      />

      <BusinessTypePage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'businessType'
            ? 'visible bg-white flex flex-col justify-center items-center rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28 px-2'
            : 'hidden'
        }
      />

      <BusinessAboutPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'businessAbout'
            ? 'visible bg-white flex flex-col justify-center items-center rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28'
            : 'hidden'
        }
      />

      <BusinessMoreInfoPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'businessMoreInfo'
            ? 'visible bg-white rounded-3xl flex flex-col justify-center items-center relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28'
            : 'hidden'
        }
        handleModalOpen={handleModalOpen}
      />

      <QuizProfessionPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageSeven'
            ? 'quiz_profession_page visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24'
            : 'quiz_profession_page hidden'
        }
        handleModalOpen={handleModalOpen}
      />

      <QuizExpertise
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageEight'
            ? 'quiz_expertise_page visible px-4 md:px-0 flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_expertise_page hidden'
        }
      />

      <BusinessKeywordsPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'businessKeywords'
            ? 'visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'hidden'
        }
        handleModalOpen={handleModalOpen}
      />

      <QuizFirstStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageStep1'
            ? 'quiz_first_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-2 pb-20 mb-24'
            : 'quiz_first_step hidden'
        }
      />
      <QuizSecondStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageStep2'
            ? 'quiz_second_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-2 mb-24'
            : 'quiz_second_step hidden'
        }
      />
      <QuizGenieStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageGenieStep'
            ? 'quiz_second_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-2 mb-24'
            : 'quiz_second_step hidden'
        }
      />
      <QuizThirdStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={
          currentPage === 'pageStep3'
            ? 'quiz_third_step visible relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-2 pb-10 mb-24'
            : 'quiz_third_step hidden'
        }
      />

      {currentPage === 'pageBrandaPreResult' && <BrandaPreResultInfo
          handleShowPage={handleShowPage}
          className='branda_result_page_2 visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full pb-20 pt-10 left-0 md:mx-auto md:mt-0 mb-24'
        />
      }

      {currentPage === 'pageBrandaResult' && <BrandaResult
          handleShowPage={handleShowPage}
          className='branda_result visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 left-0 md:mx-auto md:mt-0 mb-24'
        />
      }
    </>
  )
}
