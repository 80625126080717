/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect, useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import subscriptionPlans from '../../../temp/subscriptionplansinfo'
import subscriptionPlansHome from '../../../temp/subscriptionplansinfoHome'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// Import required modules
import { Navigation, Pagination } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'app/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import BackButton from 'components/atoms/BackButton/BackButton'
import { Divider } from '@mui/material'
import { Check } from '@mui/icons-material'

interface Props {
  homeSubscription?: boolean
}

interface Subscription {
  id: number
  branda_type: string
  price: string
  description: string
  subtitle: string
  list_items: string
  paypro_url?: string
  cta_label: string
  cta_color?: string
  cta_font_color?: string
  footnote?: string
  is_disabled?: boolean
  product_id: number
  discount_price?: string
}

export default function SubscriptionPageInfo ({ homeSubscription = false }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const firstName = currentUser?.first_name ?? ''
  const lastName = currentUser?.last_name ?? ''
  let payProExtraParams = !homeSubscription ? `&billing-email=${currentUser?.email}&billing-country=${currentUser?.country}&billing-first-name=${firstName}` : ''
  const [backPage] = useState('')

  const navigate = useNavigate()
  const handleBackPage = () => {
    navigate(-1)
  }

  if (lastName.length > 0) {
    payProExtraParams += `&billing-last-name=${lastName}`
  }

  const updateScreenWidth = () => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)
    return () => window.removeEventListener('resize', updateScreenWidth)
  })

  function renderSubscriptionHomePlans () {
    // @ts-expect-error it was here
    return renderSubscriptions(subscriptionPlansHome)
  }

  function renderSubscriptionPlans () {
    return renderSubscriptions(subscriptionPlans)
  }

  const getSubscriptionClassName = (subscriptionId: number) => {
    const userSignedIn = currentUser != null
    const hasSubscription = userSignedIn && currentUser.subscription?.[0]?.type === subscriptionId && currentUser?.subscription[0].is_active
    const activeClasses = 'shadow-lg shadow-gray-300'

    if (userSignedIn && hasSubscription) {
      return activeClasses
    }

    if (subscriptionId === -1 && currentUser?.subscription[0]?.type === -1) {
      return activeClasses
    }

    if (userSignedIn && (currentUser.subscription?.[0] == null || !currentUser.subscription?.[0].is_active) && subscriptionId === 1) {
      return activeClasses
    }

    if (!userSignedIn && subscriptionId === 1) {
      return activeClasses
    }

    return 'hover:shadow-md hover:shadow-gray-100'
  }

  const getSubscriptionMobileClassName = (subscriptionId: number) => {
    const userSignedIn = currentUser != null
    const hasSubscription = userSignedIn && currentUser.subscription?.[0]?.type === subscriptionId && currentUser?.subscription[0].is_active
    const activeClasses = 'rounded border-purple-400 border-2'

    if (userSignedIn && hasSubscription) {
      return activeClasses
    }

    if (userSignedIn && ((currentUser.subscription?.[0]) == null || !currentUser.subscription?.[0].is_active) && subscriptionId === 1) {
      return activeClasses
    }

    return ''
  }

  const getSubscriptionColor = (subscriptionId: number) => {
    return subscriptionId === 1 ? '#ffffff' : '#000'
  }

  const renderActiveStatus = (subscriptionId: number) => {
    let isSubscriptionActive = false
    console.log(currentUser?.subscription[0])
    if (subscriptionId === -1 && currentUser?.subscription[0]?.type === null) {
      isSubscriptionActive = true
    } else if (currentUser?.subscription[0]?.type === subscriptionId && currentUser?.subscription[0].is_active) {
      isSubscriptionActive = true
    }

    if (isSubscriptionActive) {
      return (
        <span className='inline-block p-3 rounded text-lime-700 font-bold ml-2'><Check fontSize={'small'} /> Active</span>
      )
    }

    return null
  }

  function renderSubscriptions (subscriptions: Subscription[]) {
    return subscriptions.map((subscription) => (
      <div key={subscription.id} className={`w-[28%] py-12 px-10 relative rounded ${subscription.id === 1 ? 'text-white premium-plan' : 'other-plans'} ${!subscription.paypro_url ? 'opacity-75 cursor-default' : ''} ${getSubscriptionClassName(subscription.id)}`}>
        <span className='plan-name inline-block text-sm py-3 px-4 rounded text-black'>{subscription.branda_type}</span>
        {renderActiveStatus(subscription.id)}
        <p className='my-4'>{subscription.description}</p>
        <Divider color={'white'}/>
        {subscription.discount_price && <p className='text-xl mt-6 line-through opacity-75'>{subscription.discount_price}</p>}
        <p className={`flex items-center gap-x-3 mb-3 ${subscription.discount_price === undefined ? 'mt-6' : ''}`}>
          <span className='text-4xl font-bold'>{subscription.price}</span> {subscription.subtitle}
        </p>
        {subscription.discount_price && <p className='mb-5 text-sm font-bold'>Special Launch Offer</p>}
        <Divider color={'white'} className='my-6' />

          {subscription.list_items.split('\n').map((line, index) => (
          <p key={index} className='text-xs sm:text-base md:text-sm lg:text-lg mt-4 flex items-center'>
            <FontAwesomeIcon
              icon={solid('circle-check')}
              style={{ color: `${getSubscriptionColor(subscription.id)}`, alignSelf: 'center' }}
              className='mr-2'
            />
            <span className='text-xs' dangerouslySetInnerHTML={{ __html: line }}></span>
          </p>
          ))}

        {subscription.paypro_url &&
          <a target={currentUser !== null && !homeSubscription ? '_blank' : '_self'}
             href={`${subscription.paypro_url}${currentUser !== null ? payProExtraParams : ''}`}
             className={`text-sm p-2 block text-center w-full bg-[${subscription.cta_color ?? '#000'}] text-[${subscription.cta_font_color ?? '#fff'}] mt-8 font-bold rounded`} rel="noreferrer">
              {subscription.cta_label}
            </a>
        }

        {!subscription.paypro_url && <div className='mt-8 p-2'>&nbsp;</div>}

        {homeSubscription && <p className='text-xs mt-2 text-center' dangerouslySetInnerHTML={{ __html: subscription.footnote ?? '&nbsp;' }}></p>}
        {subscription.id !== 1 && <div className='h-20'>&nbsp;</div>}
      </div>
    ))
  }

  function renderMobile (subscriptions: Subscription[]) {
    return subscriptions.map((subscription) => (
      <SwiperSlide
        key={subscription.id}
        className={`relative py-2 !shadow-none px-1 md:p-4 ${getSubscriptionMobileClassName(subscription.id)} `}>
          <div key={subscription.id} className={`relative !shadow-none p-4 py-8 ${subscription.id === 1 ? 'text-white premium-plan' : 'other-plans'} ${getSubscriptionClassName(subscription.id)}`}>
            <span className='plan-name inline-block py-2 px-3 rounded text-black text-sm'>{subscription.branda_type}</span>
            {renderActiveStatus(subscription.id)}
            <p className='my-4 text-sm'>{subscription.description}</p>
            <Divider color={'white'}/>

            {subscription.discount_price && <p className='text-xl mt-6 line-through opacity-75'>{subscription.discount_price}</p>}
            <p className={`flex items-center gap-x-3 mb-3 ${subscription.discount_price === undefined ? 'mt-6' : ''}`}>
              <span className='text-4xl font-bold'>{subscription.price}</span> {subscription.subtitle}
            </p>
            {subscription.discount_price && <p className='mb-5 text-sm font-bold'>Special Launch Offer</p>}
            <Divider color={'white'} className='my-6'/>

            {subscription.list_items.split('\n').map((line, index) => (
              <p key={index} className='text-xs sm:text-base md:text-sm lg:text-lg mt-4 flex items-center'>
                <FontAwesomeIcon
                  icon={solid('circle-check')}
                  style={{ color: `${getSubscriptionColor(subscription.id)}`, alignSelf: 'center' }}
                  className='mr-2'
                />
                <span dangerouslySetInnerHTML={{ __html: line }}></span>
              </p>
            ))}

            {subscription.paypro_url &&
                <a target={currentUser !== null && !homeSubscription ? '_blank' : '_self'} href={`${subscription.paypro_url}${currentUser !== null ? payProExtraParams : ''}`} className={`p-2 block text-center w-full bg-[${subscription.cta_color ?? '#000'}] text-[${subscription.cta_font_color ?? '#fff'}] mt-8 font-bold rounded`} rel="noreferrer">
                  {subscription.cta_label}
                </a>
            }

            {subscription.footnote && <p className='text-xs mt-6 text-center'>{subscription.footnote}</p>}
          </div>
      </SwiperSlide>
    ))
  }

  function renderHomeMobilePlans () {
    // @ts-expect-error it was here
    return renderMobile(subscriptionPlansHome)
  }

  function renderMobilePlans () {
    return renderMobile(subscriptionPlans)
  }

  return (
    <div
      className={`bg-white ${homeSubscription ? 'px-2 py-8 md:px-8' : 'p-8'} mb-12 md:w-full mx-5 md:mx-0 mt-10 md:mt-0 flex flex-col gap-3 justify-center rounded`}>
      <div className="flex w-full">
        <BackButton
          backPage={backPage}
          handleBackPage={handleBackPage}
          text=""
          className="quiz_back_button2 block "
        />
      </div>
      <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-3'>
        <Header className='font-medium text-center md:text-left text-2xl md:text-4xl text-gray-900'
                text='Start Growing Online!'/>
        <Header className=' text-sm md:text-lg font-normal text-center mb-8 md:text-left break-words text-gray-500'
                text="Choose one of our Brand Fitness Plans designed especially to meet your needs."/>
        {
          isScreenWidthAbove768
            ? (
              <div
                className="flex w-full justify-center items-end font-normal text-base p-0 leading-6 gap-x-12 mb-4 my-12 md:my-0 relative">
                {homeSubscription ? renderSubscriptionHomePlans() : renderSubscriptionPlans()}
              </div>
              )
            : (
              <>
                <div className="flex w-full items-center justify-center">
                  Swipe for more plans <FontAwesomeIcon icon={solid('arrow-right')} className='ml-3'/>
                </div>
                <Swiper
                  pagination={{
                    type: 'progressbar'
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper w-full"
                >
                  {homeSubscription ? renderHomeMobilePlans() : renderMobilePlans()}
                </Swiper>
              </>
              )}
      </div>
    </div>
  )
}
