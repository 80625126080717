// noinspection TypeScriptValidateTypes

import { Divider, InputAdornment, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React, { type ReactElement, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { FacebookRounded, Instagram, LinkedIn, Search } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  type ContentHistory,
  setContentHistory, setHistoryItemClicked,
  setSelectedContentHistory
} from '../../../app/quiz/ContentGeneratorSlice'
import DateDisplay from '../../atoms/DateDisplay/DateDisplay'
import Tooltip from '@mui/material/Tooltip'
import { users } from '../../../app/services/Api'
import { useParams } from 'react-router-dom'

interface Props {
  className?: string
}

interface CustomListItemTextProps {
  historyItem: ContentHistory
}

export default function GenerationHistory ({ className }: Props) {
  const [items, setItems] = useState<ContentHistory[]>([])
  const [filteredItems, setFilteredItems] = useState<ContentHistory[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const selectedHistoryItem = useAppSelector(state => state.contentGeneratorSlice.selectedContentHistory)
  const historyItems = useAppSelector(state => state.contentGeneratorSlice.contentHistories)
  const user = useAppSelector(state => state.quizReducer.currentUser)
  const dispatch = useAppDispatch()
  const { id, historyId } = useParams()

  const platforms: Record<string, ReactElement> = {
    Facebook: <FacebookRounded fontSize={'large'} sx={{
      color: 'blue'
    }}/>,
    Instagram: <Instagram fontSize={'large'} sx={{
      color: '#C13584'
    }}/>,
    Linkedin: <LinkedIn fontSize={'large'} sx={{
      color: '#0077B5'
    }}/>
  }

  useEffect(() => {
    if (user === null || id === undefined) {
      return
    }

    void users.getGenerationHistoryList(parseInt(id), user.id).then((response) => {
      const localItems: ContentHistory[] = response
      dispatch(setContentHistory(localItems))

      if (historyId !== undefined) {
        const item = localItems.find((localItem) => localItem.id === parseInt(historyId)) ?? null
        dispatch(setSelectedContentHistory(item))
      }
    })
  }, [user])

  useEffect(() => {
    setItems([...historyItems])
    setFilteredItems([...historyItems])
  }, [historyItems])

  const handleSearch = (value: string) => {
    setSearchValue(value)
    filterItems(value)
  }

  const filterItems = (value: string) => {
    if (value === '') {
      setFilteredItems(items)
      return
    }

    setFilteredItems(items.filter((item) => item?.task.task_title.toLowerCase().includes(value) || item.response?.toLowerCase().includes(value)))
  }

  const handleHistoryItemClick = (item: ContentHistory | null) => {
    dispatch(setSelectedContentHistory(item))
    dispatch(setHistoryItemClicked(true))
  }

  const CustomListItemText = (props: CustomListItemTextProps) => {
    const { historyItem } = props
    let text = historyItem.task.task_title

    if ((historyItem.task.is_free_writing || historyItem.task.is_rewrite) && historyItem.personalization !== null && historyItem.personalization !== '') {
      text = historyItem.personalization
        .split(' ', 4)
        .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        .join(' ') + (historyItem.personalization.split(' ').length > 4 ? '...' : '')
    }

    return (
      <ListItemText primary={text} />
    )
  }

  return (
    <div className={`bg-gray-50 p-5 pb-7 shadow history ${className}`}>
      <p className='text-xl'>Post History</p>
      <br/>
      <TextField
        hiddenLabel={true}
        placeholder={'Search previous prompts...'}
        id="outlined-start-adornment"
        className={'w-full'}
        onChange={(e) => { handleSearch(e.target.value) }}
        value={searchValue}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#ffffff'
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }}
      />
      {filteredItems.length === 0
        ? <p key={0} className='mt-6 text-slate-500 text-center'>Nothing generated yet</p>
        : ''
      }
      <List className={`max-h-96 overflow-y-auto bg-white border rounded-lg !mt-4 !py-0 ${filteredItems.length === 0 ? 'hidden' : ''}`}>
        {filteredItems.map((item, index) => (
          <>
            <ListItem
              onClick={() => {
                handleHistoryItemClick(item)
              }}
              key={item.id}
              className={`hover:bg-[#f1e0ff] cursor-pointer ${(selectedHistoryItem?.id === item.id) || (selectedHistoryItem === null && item.id === null) ? 'bg-[#f1e0ff]' : ''}`}>
              <ListItemIcon>
                {platforms[item.task.platform]}
              </ListItemIcon>
              <Tooltip title={item.id !== null ? <DateDisplay className={'text-xs'} timestamp={item.created_at ?? 0} /> : ''}>
                <CustomListItemText historyItem={item} />
              </Tooltip>
            </ListItem>
            {(index + 1) < items.length
              ? <Divider key={'divider-' + item.id} component="li" />
              : ''
            }

          </>
        ))}
      </List>
    </div>
  )
}
