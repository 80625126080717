/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable */

import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {setCurrentUser} from '../../../app/quiz/quizSlice'
import {users} from "../../../app/services/Api";
import {toast} from "react-toastify";
import {setCredits} from "../../../app/quiz/userProfile";
import {useNavigate} from "react-router-dom";

export default function BrandaResultForm () {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const email = useAppSelector(state => state.quizReducer.email)
  const password = useAppSelector(state => state.quizReducer.password)
  const branda = useAppSelector(state => state.quizReducer.Branda)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  const clearLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.clear()
    }
  }

  const handleSignIn = async () => {
    if (!currentUser) {
      await users.signIn(email, password, true, branda).then((response) => {
        if (response.jwt) {
          dispatch(setCurrentUser(response.user))
          toast.success('You have been successfully registered.')

          void users.getRemainingCredits().then((response) => {
            dispatch(setCredits(response.credits))
          })
        } else {
          toast.error('Incorrect email or password!')
        }
      })
        .catch(err => {
          toast.error(err)
          console.log(err)
        })
    }
    toast.success('You have been successfully registered.')
    clearLocalStorage()
    await users.updateRegisteredUser(currentUser?.id ?? 0, { has_finished_steps: true, has_finished_quiz: true }, true).finally(() => {
      users.getCurrentUser(true).then((response) => {
        if (!response.response) {
          dispatch(setCurrentUser(response))
        } else {
          dispatch(setCurrentUser(null))
        }
      })
        .catch((err) => {
          console.log(err)
        })
      navigate('/content-generator')
    }).finally(() => {
      void users.getRemainingCredits().then((response) => {
        dispatch(setCredits(response.credits))
      })
    })
  }

  return (
    <button onClick={handleSignIn} className="p-4 w-44 h-14 bg-white rounded-lg text-base font-medium text-center text-black mt-5">
      Build my Brand!
    </button>
  )
}
