import QuizSecondStepTitle from '../../molecules/QuizSecondStepTitle/QuizSecondStepTitle'
import QuizSecondStepForm from '../../molecules/QuizSecondStepForm/QuizSecondStepForm'
import Steps from '../../molecules/Steps/Steps'
import React from 'react'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizSecondStep ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <QuizSecondStepTitle className='flex flex-col justify-center relative visible_1 items-center gap-2 mt-10 md:mt-5'/>
      <QuizSecondStepForm handleShowPage={handleShowPage} className='flex flex-col relative visible_1 justify-center items-center gap-2 my-1 mx-3 md:mx-1 lg:mx-3'/>
    </div>
  )
}
