import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { HelpRounded } from '@mui/icons-material'
import {
  setIsFromBrandaSuggestion,
  setSelectedContentHistory,
  setSelectedTopic,
  type Topic
} from '../../../app/quiz/ContentGeneratorSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ClickAwayListener from '@mui/material/ClickAwayListener'

interface Props {
  topic: Topic
  isTrialUser: boolean
  index: number
}

export default function PremiumTopicBox ({ topic, isTrialUser, index }: Props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const isMobile = window.innerWidth < 768

  const topicColors = [
    '#b6cbf6',
    '#b3baf3',
    '#c2b4f6',
    '#d6b2f8',
    '#f8b3e3',
    '#f8aebb',
    '#f4d8ae',
    '#f4ebb7',
    '#e9f4b7',
    '#d9f4b7'
  ]

  const handleTopicSelection = (topic: Topic, isSuggestion: boolean = false) => {
    dispatch(setSelectedContentHistory(null))
    dispatch(setIsFromBrandaSuggestion(isSuggestion))
    dispatch(setSelectedTopic(topic))
    navigate('/content-generator/' + topic.id)
  }

  const PremiumBadge = () => {
    return (
      <span
        className={'rounded-full z-10 absolute top-1.5 font-bold text-[10px] md:text-xs tracking-widest py-0.5 px-1.5 md:px-3 bg-[rgba(0,0,0,0.2)]'}>Premium</span>
    )
  }

  return (
    <ClickAwayListener onClickAway={() => {
      setIsTooltipOpen(false)
    }}>
    <Tooltip
      disableFocusListener={true}
      disableTouchListener={true}
      disableHoverListener={true}
      onClose={() => {
        setIsTooltipOpen(false)
      }}
      open={isTooltipOpen}
      title={topic.description ?? ''}>
      <div
        onClick={() => {
          if (!topic.is_trial && isTrialUser) {
            if (isMobile) {
              setIsTooltipOpen(true)
            }

            return
          }

          handleTopicSelection(topic)
        }}

        className={`${!topic.is_trial && isTrialUser ? '!opacity-60 cursor-default' : 'cursor-pointer'} relative hover:opacity-85 inline-flex flex-col justify-center items-center text-center p-3 bg-[${topicColors[index]}] rounded-xl h-32 w-[45%] md:w-56`}>
                <span>{topic.name}
                  {!topic.is_trial && isTrialUser &&
                      <Tooltip
                          title={topic.description ?? ''}>
                          <HelpRounded fontSize={'inherit'} className='-mt-0.5' />
                      </Tooltip>
                  }
                </span>
        {!topic.is_trial && isTrialUser && <PremiumBadge />}
      </div>
    </Tooltip>
    </ClickAwayListener>
  )
}
