import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import React, { useEffect, useState } from 'react'
import Card from '../../atoms/Card/Card'
import { setIsBusiness } from '../../../app/quiz/quizSlice'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'

interface Props {
  className: string
}

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BusinessTypePageForm ({ className, handleShowPage }: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const isBusiness = useAppSelector(state => state.quizReducer.isBusiness)
  const [isDisabled, setIsDisabled] = useState(true)
  const navigate = useNavigate()

  const [cards, setCards] = useState([
    {
      id: 1,
      text: 'I’m a Solo Entrepreneur',
      tooltip: 'People who work alone. Service Providers, Coaches, Consultants, Therapists, Freelancers etc.',
      isActive: false
    },
    {
      id: 2,
      text: 'I’m a Business Owner',
      tooltip: 'People who own a brand company, employ other people, sell products, etc.',
      isActive: false
    }
  ])

  const handleSelection = (id: number) => {
    cards.map((item) => {
      item.isActive = id === item.id
      return item
    })

    dispatch(setIsBusiness(id === 2))

    setIsDisabled(cards.filter((card) => card.isActive).length === 0)
    setCards([...cards])
  }

  const handleUpdateUser = (e: React.FormEvent) => {
    e.preventDefault()

    if (currentUser === null) {
      navigate('/sign-in')
      return
    }

    void users.updateRegisteredUser(currentUser.id, {
      is_business: isBusiness,
      step_name: isBusiness ? 'BusinessAbout' : 'Profession'
    }, true)

    handleShowPage(isBusiness ? 'businessAbout' : 'pageSeven')
  }

  useEffect(() => {
    if (currentUser !== null) {
      handleSelection(currentUser.is_business ? 2 : 0)
    }
  }, [currentUser])

  return (
    <div className={className}>
      <div
        className={`flex ${isSmallerScreen ? 'flex-col items-center' : 'flex-row'} justify-center w-full`}>
        {cards.map((card) => (
          <Card
            key={card.id}
            id={card.id}
            handleCardClick={() => {
              handleSelection(card.id)
            }}
            tooltip={card.tooltip}
            isActive={card.isActive}
            text={card.text}
            className={`${isSmallerScreen ? '!w-full' : ''} business_selection_button border border-gray-300 text-black flex justify-center items-center relative !h-16 !py-6`}
          />
        ))}
      </div>

      <button
        onClick={handleUpdateUser}
        disabled={isDisabled}
        className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-2.5"
      >
        Next
      </button>
    </div>
  )
}
