/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setProfessionPage } from 'app/quiz/adminSlice'
import AddProfession from './CrudOperations/AddProfession'
import EditProfession from './CrudOperations/EditProfession'
import DeleteProfession from './CrudOperations/DeleteProfession'
import { professions } from 'app/services/Api'

interface Profession {
  id: number
  name: string
}

export default function ProfessionAdmin () {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [professionData, setProfessionData] = useState<Profession[]>([])
  const [selectedProfession, setSelectedProfession] = useState<Profession | null>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedDeleteProfessionId, setSelectedDeleteProfessionId] = useState<number | null>(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const adminProfessionPage = useAppSelector(state => state.adminFaq.professionPage)

  useEffect(() => {
    if (adminProfessionPage === 'profession-admin') {
      fetchData()
    }
  }, [adminProfessionPage])

  const fetchData = async () => {
    try {
      if (currentUser === null) {
        return
      }

      const response = await professions.getAllProfessions(currentUser.id)
      setProfessionData(response)
    } catch (error) {
      console.error('Error fetching Profession data:', error)
    }
  }

  const handleAddProfessionClick = () => {
    dispatch(setProfessionPage('profession-admin-add'))
  }

  const handleAddProfession = (newProfession: Profession) => {
    dispatch(setProfessionPage('profession-admin-add'))
    setProfessionData((prevProfessionData) => [...prevProfessionData, newProfession])
  }

  const handleEditClick = (profession: Profession) => {
    dispatch(setProfessionPage('profession-admin-edit'))
    setSelectedProfession(profession)
  }

  const handleEditProfession = (editedProfession: Profession) => {
    dispatch(setProfessionPage('profession-admin-edit'))
    setProfessionData((prevProfessionData) =>
      prevProfessionData.map((profession) => (profession.id === editedProfession.id ? editedProfession : profession))
    )

    setSelectedProfession(null)
  }

  const handleDeleteClick = (professionId: number) => {
    setSelectedDeleteProfessionId(professionId)
    setShowDeleteDialog(true)
  }

  const handleDeleteProfession = () => {
    setProfessionData((prevProfessionData) => prevProfessionData.filter((profession) => profession.id !== selectedDeleteProfessionId))
    setSelectedDeleteProfessionId(null)
    setShowDeleteDialog(false)
  }

  const handleCancelDelete = () => {
    setSelectedDeleteProfessionId(null)
    setShowDeleteDialog(false)
  }

  const columns = [
    'Name', ''
  ]

  return (
    <>
      <div className='flex flex-col w-2/4 mb-28'>
      <div className='my-7 text-3xl font-semibold'>
          Profession List
        </div>
        {adminProfessionPage === 'profession-admin' && (
        <>
        <button className="w-64 bg-fuchsia-800 text-white font-bold mb-6 py-2 px-4 rounded" onClick={handleAddProfessionClick}>Add New Profession</button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                  {professionData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((profession) => (
                      <TableRow key={profession.id}>
                        <TableCell>{profession.name}</TableCell>
                        <TableCell>
                          <div className="flex">
                            <Button variant="outlined" onClick={() => { handleEditClick(profession) }}>Edit</Button>
                            <span className="mx-1"></span>
                            <Button variant="outlined" color="error" onClick={() => { handleDeleteClick(profession.id) }}>Delete</Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={professionData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10))
                  setPage(0)
                }}
              />
            </TableContainer>
          </>
        )}
        {adminProfessionPage === 'profession-admin-add' && <AddProfession onAddProfession={handleAddProfession} />}
        {adminProfessionPage === 'profession-admin-edit' && selectedProfession && <EditProfession existingProfession={selectedProfession} onEditProfession={handleEditProfession} />}
        {showDeleteDialog && selectedDeleteProfessionId && (
          <DeleteProfession professionId={selectedDeleteProfessionId} onDeleteProfession={handleDeleteProfession} onClose={handleCancelDelete} />
        )}
      </div>
    </>
  )
}
