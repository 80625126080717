import React from 'react'
import BusinessMoreInfoPageInfo from '../../molecules/BusinessMoreInfoPage/BusinessMoreInfoPageInfo'
import BusinessMoreInfoPageForm from '../../molecules/BusinessMoreInfoPage/BusinessMoreInfoPageForm'
import AskBranda from '../../atoms/Icon/AskBranda'
import Steps from '../../molecules/Steps/Steps'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function BusinessMoreInfoPage ({ className, currentPage, handleShowPage, handleModalOpen }: Props) {
  return (
    <div className={className}>
      <div className='flex justify-end w-full custom-quiz-margin pt-5 pr-5'>
        <AskBranda handleModalOpen={handleModalOpen}/>
      </div>
      <Steps currentPage={currentPage} />
      <BusinessMoreInfoPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-10'/>
      <BusinessMoreInfoPageForm handleShowPage={handleShowPage}
                                className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
