import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'

interface Props {
  className?: string
  handleNext: () => void
  handlePrevious: () => void
  currentCount: number
  totalCount: number
}

export default function Navigator ({ className, handleNext, handlePrevious, currentCount, totalCount }: Props) {
  const isFromBrandaSuggestion = useAppSelector((state) => state.contentGeneratorSlice.isFromBrandaSuggestion)
  const topic = useAppSelector((state) => state.contentGeneratorSlice.topic)
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    if (isFromBrandaSuggestion) {
      setTitle('Suggestions')
      return
    }

    if (currentCount === 0 || currentCount > totalCount) {
      setTitle('History')
      return
    }

    if (totalCount === 0) {
      setTitle('')
      return
    }

    if (topic !== null) {
      setTitle(topic.short_name ?? topic.name)
    }
  }, [currentCount, totalCount])

  return (
    <div className={className}>
        <div>
          {totalCount > 0 && <><KeyboardDoubleArrowLeft className='cursor-pointer' onClick={handlePrevious}/> <span className='w-20 hidden md:inline-block'></span></>}
        </div>
      <div>{title}</div>
      <div>
      {totalCount > 0 && <span className='next-button'><span onClick={handleNext} className='cursor-pointer w-20 text-right text-sm hidden md:inline-block -mb-2'>Next Post</span> <KeyboardDoubleArrowRight className='cursor-pointer' onClick={handleNext}/></span>}
        </div>
    </div>
  )
}
