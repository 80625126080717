const subscriptionPlans = [
  {
    id: -1,
    description: 'Basic plan for those who are just checking us out',
    branda_type: 'Trial',
    price: 'Free',
    subtitle: '',
    list_items: '5 FREE Social Media Posts generation \n Discover your unique Brand Identity \n Restricted Viral Post ideas access',
    product_id: -1,
    cta_label: 'Unavailable',
    cta_color: '#000000',
    cta_font_color: '#ffffff',
    is_disabled: true
  },
  {
    id: 1,
    description: 'Best for Solo-Entrepeneurs and Micro-Businesses',
    branda_type: 'Premium',
    discount_price: '$19.99',
    price: '$9.99',
    subtitle: 'Per Month',
    list_items: 'Up to <b>50</b> Social Media Posts generations \n Discover your unique Brand Identity \n Unlimited viral posts ideas access \n Write your own original posts \n Branda score <span class="coming-soon">Coming Soon</span>',
    paypro_url: 'https://store.payproglobal.com/checkout?products[1][id]=101664',
    product_id: 101664,
    cta_label: 'Purchase',
    cta_color: '#F9D783',
    cta_font_color: '#3D4483',
    is_disabled: true
  },
  {
    id: 2,
    description: 'Contact us for SMB’s and Enterprise custom plans',
    branda_type: 'Custom',
    price: 'Contact Us',
    subtitle: '',
    list_items: 'Custom-made branding plans \n Discover myBranda® for Business \n Become an affiliate',
    paypro_url: '/contact-us?message=Tell me more about custom business plans!',
    product_id: 0,
    cta_label: 'Book A Demo',
    cta_color: '#000000',
    cta_font_color: '#ffffff',
    is_disabled: true
  }
]

export default subscriptionPlans
