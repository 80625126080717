import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  userName: string | null
  userEmail: string | null
  branda: string | null
  gender: string | null
  birthDate: string | null
  credits: number | null
}

const initialState: UserState = {
  userName: null,
  userEmail: null,
  branda: null,
  gender: null,
  birthDate: null,
  credits: 0
}

export const userProfile = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload
    },
    setBranda: (state, action: PayloadAction<string>) => {
      state.branda = action.payload
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload
    },
    setBirthdate: (state, action: PayloadAction<string>) => {
      state.birthDate = action.payload
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload
    },
    setCredits: (state, action: PayloadAction<number>) => {
      state.credits = action.payload
    }
  }
})

export const {
  setUserName, setBranda, setGender, setBirthdate, setUserEmail, setCredits
} = userProfile.actions

export default userProfile.reducer
