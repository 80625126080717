import { ReactComponent as QuizPageOneImg } from '../../../img/gender-image.svg'
import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizGenderPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <QuizPageOneImg/>
      <Header className='text-2xl font-medium text-center mt-10 mb-5' text="I am a" />
    </div>
  )
}
